import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import PrivateRoute from "./route";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import AccountVerification from "./components/auth/AccountVerification";
import ResendVerification from "./components/auth/ResendVerification";
import ResetPassword from "./components/auth/ResetPassword";
import Dashboard from "./components/dashboard/Dashboard";
import ChangePassword from "./components/auth/ChangePassword";
import Profile from "./components/auth/Profile";
import Institution from "./components/institution/Institution";
import CreateInstitution from "./components/institution/CreateInstitution";
import SetContribution from "./components/institution/SetContribution";
import Members from "./components/members/Members";
import CreateMember from "./components/members/CreateMember";
import UploadMembers from "./components/members/UploadMembers";
import Contributions from "./components/contributions/Contributions";
import CreateContribution from "./components/contributions/CreateContribution";
import UploadContributions from "./components/contributions/UploadContributions";
import TotalContributions from "./components/contributions/TotalContributions";
import Loans from "./components/loans/Loans";
import LoanSettings from "./components/loans/LoanSettings";
import CreateLoanSetting from "./components/loans/CreateLoanSetting";
import CreateLoan from "./components/loans/CreateLoan";
import TotalLoans from "./components/loans/TotalLoans";
import ExpenseSettings from "./components/expenses/ExpenseSettings";
import CreateExpenseSetting from "./components/expenses/CreateExpenseSetting";
import Expenses from "./components/expenses/Expenses";
import CreateExpense from "./components/expenses/CreateExpense";
import TotalExpenses from "./components/expenses/TotalExpenses";
import UploadExpenses from "./components/expenses/UploadExpenses";
import EditExpense from "./components/expenses/EditExpense";
import OfficeExpenses from "./components/expenses/OfficeExpenses";
import MembersExpenses from "./components/expenses/MembersExpenses";
import IncomeSettings from "./components/incomes/IncomeSettings";
import CreateIncomeSetting from "./components/incomes/CreateIncomeSetting";
import Incomes from "./components/incomes/Incomes";
import CreateIncome from "./components/incomes/CreateIncome";
import TotalIncomes from "./components/incomes/TotalIncomes";
import UploadIncomes from "./components/incomes/UploadIncomes";
import EditIncome from "./components/incomes/EditIncome";
import OfficeRevenues from "./components/incomes/OfficeRevenues";
import MembersIncomes from "./components/incomes/MembersIncomes";
import DonationSettings from "./components/donations/DonationSettings";
import CreateDonationSetting from "./components/donations/CreateDonationSetting";
import Donations from "./components/donations/Donations";
import CreateDonation from "./components/donations/CreateDonation";
import TotalDonations from "./components/donations/TotalDonations";
import UploadDonations from "./components/donations/UploadDonations";
import EditDonation from "./components/donations/EditDonation";
import OfficeDonations from "./components/donations/OfficeDonations";
import MembersDonations from "./components/donations/MembersDonations";
import ViewMember from "./components/members/ViewMember";
import Users from "./components/users/Users";
import CreateUser from "./components/users/CreateUser";
import Clients from "./components/admin/Clients";

const Navigation = ({ store }) => {
  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/auth/sign-up" component={SignUp} />
          <Route
            exact
            path="/auth/resend-code"
            component={ResendVerification}
          />
          <Route
            exact
            path="/auth/account-verification"
            component={AccountVerification}
          />
          <Route exact path="/auth/reset-password" component={ResetPassword} />
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path={"/change-password"}
              component={ChangePassword}
            />
            <PrivateRoute exact path={"/profile"} component={Profile} />
            <PrivateRoute exact path={"/institution"} component={Institution} />
            <PrivateRoute
              exact
              path={"/institution/create"}
              component={CreateInstitution}
            />
            <PrivateRoute
              exact
              path={"/institution/set-contribution"}
              component={SetContribution}
            />
            <PrivateRoute exact path={"/members"} component={Members} />
            <PrivateRoute
              exact
              path={"/members/create"}
              component={CreateMember}
            />
            <PrivateRoute
              exact
              path={"/members/upload"}
              component={UploadMembers}
            />
            <PrivateRoute
              exact
              path={"/contributions"}
              component={Contributions}
            />
            <PrivateRoute
              exact
              path={"/contributions/create"}
              component={CreateContribution}
            />
            <PrivateRoute
              exact
              path={"/contributions/upload"}
              component={UploadContributions}
            />
            <PrivateRoute
              exact
              path={"/contributions/total-contributions/:month/:year"}
              component={TotalContributions}
            />
            <PrivateRoute exact path={"/loans"} component={Loans} />
            <PrivateRoute
              exact
              path={"/loan-settings"}
              component={LoanSettings}
            />
            <PrivateRoute
              exact
              path={"/loan-settings/create"}
              component={CreateLoanSetting}
            />
            <PrivateRoute exact path={"/loans/create"} component={CreateLoan} />
            <PrivateRoute
              exact
              path={"/loans/total-loans/:month/:year"}
              component={TotalLoans}
            />
            <PrivateRoute
              exact
              path={"/expense-settings"}
              component={ExpenseSettings}
            />
            <PrivateRoute
              exact
              path={"/expense-settings/create"}
              component={CreateExpenseSetting}
            />
            <PrivateRoute exact path={"/expenses"} component={Expenses} />
            <PrivateRoute
              exact
              path={"/expenses/create"}
              component={CreateExpense}
            />
            <PrivateRoute
              exact
              path={"/expenses/total-expenses/:month/:year"}
              component={TotalExpenses}
            />
            <PrivateRoute
              exact
              path={"/expenses/upload"}
              component={UploadExpenses}
            />
            <PrivateRoute
              exact
              path={"/expenses/edit-expense/:id"}
              component={EditExpense}
            />
            <PrivateRoute
              exact
              path={"/expenses/office-expenses"}
              component={OfficeExpenses}
            />
            <PrivateRoute
              exact
              path={"/expenses/members-expenses"}
              component={MembersExpenses}
            />

            <PrivateRoute
              exact
              path={"/income-settings"}
              component={IncomeSettings}
            />
            <PrivateRoute
              exact
              path={"/income-settings/create"}
              component={CreateIncomeSetting}
            />
            <PrivateRoute exact path={"/incomes"} component={Incomes} />
            <PrivateRoute
              exact
              path={"/incomes/create"}
              component={CreateIncome}
            />
            <PrivateRoute
              exact
              path={"/incomes/total-incomes/:month/:year"}
              component={TotalIncomes}
            />
            <PrivateRoute
              exact
              path={"/incomes/upload"}
              component={UploadIncomes}
            />
            <PrivateRoute
              exact
              path={"/incomes/edit-income/:id"}
              component={EditIncome}
            />
            <PrivateRoute
              exact
              path={"/incomes/office-incomes"}
              component={OfficeRevenues}
            />
            <PrivateRoute
              exact
              path={"/incomes/members-incomes"}
              component={MembersIncomes}
            />

            <PrivateRoute
              exact
              path={"/donation-settings"}
              component={DonationSettings}
            />
            <PrivateRoute
              exact
              path={"/donation-settings/create"}
              component={CreateDonationSetting}
            />
            <PrivateRoute exact path={"/donations"} component={Donations} />
            <PrivateRoute
              exact
              path={"/donations/create"}
              component={CreateDonation}
            />
            <PrivateRoute
              exact
              path={"/donations/total-donations/:month/:year"}
              component={TotalDonations}
            />
            <PrivateRoute
              exact
              path={"/donations/upload"}
              component={UploadDonations}
            />
            <PrivateRoute
              exact
              path={"/donations/edit-donation/:id"}
              component={EditDonation}
            />
            <PrivateRoute
              exact
              path={"/donations/office-donations"}
              component={OfficeDonations}
            />
            <PrivateRoute
              exact
              path={"/donations/members-donations"}
              component={MembersDonations}
            />
            <PrivateRoute
              exact
              path={"/members/view/:id/:staff_id"}
              component={ViewMember}
            />
            <PrivateRoute exact path={"/users"} component={Users} />
            <PrivateRoute exact path={"/users/create"} component={CreateUser} />
            <PrivateRoute exact path="/admin/clients" component={Clients} />
          </Switch>
        </React.Fragment>
      </Router>
    </Provider>
  );
};

export default Navigation;
