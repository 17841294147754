import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generalPurposeAction } from "../../actions/common-action";
import { Title } from "../common";
import { SignUpLink, AuthLinks } from "../forms/guest";
import { TextFieldGroup, Button } from "../forms/guest";
import { login } from "../../actions/auth-action";
import roles from "../../constants/roles";
import actionTypes from "../../constants/types";
import { displayToast } from "../common/Toaster";
import "./Auth.css";

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    if (auth.isAuthenticated) {
      const { user } = auth;
      switch (user.role) {
        case roles.ADMIN:
          history.push("/dashboard");
          break;
        case roles.USER:
          history.push("/dashboard");
          break;
        case roles.SUPER:
          history.push("/admin/clients");
          break;
        default:
          history.push("/dashboard");
      }
    }
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      email: values.email,
      password: values.password,
    };
    dispatch(login(user));
  };

  useEffect(() => {
    const { isAuthenticated, user } = auth;
    const { success, message } = flash;
    const { isLoading } = loading;
    if (isAuthenticated) {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
      switch (user.role) {
        case roles.ADMIN:
          window.location.href = "/dashboard";
          break;
        case roles.USER:
          window.location.href = "/dashboard";
          break;
        case roles.SUPER:
          window.location.href = "/admin/clients";
          break;
        default:
          window.location.href = "/dashboard";
      }
    }
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [auth, dispatch, flash, loading]);

  return (
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2">
      <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div className="m-login__container">
          <div className="login-section">
            <div className="m-login__signin">
              <Title title="Login" />
              <form className="m-login__form m-form" onSubmit={onSubmit}>
                <TextFieldGroup
                  placeholder={"Email"}
                  value={values.email}
                  name={"email"}
                  onChange={onChange}
                  type={"email"}
                />
                <TextFieldGroup
                  placeholder={"Password"}
                  value={values.password}
                  name={"password"}
                  onChange={onChange}
                  type={"password"}
                  className={"m-login__form-input--last"}
                />
                <AuthLinks />

                <div className="m-login__form-action">
                  <Button
                    title={"Sign In"}
                    className={"custom-button btn--black"}
                    loading={isLoading}
                  />
                </div>
              </form>
            </div>
            <SignUpLink />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
