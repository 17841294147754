const menu = {
  DASHBOARD: "DASHBOARD",
  SETTINGS: "SETTINGS",
  MEMBERS: "MEMBERS",
  CONTRIBUTIONS: "CONTRIBUTION",
  LOANS: "LOANS",
  DONATIONS: "DONATIONS",
  EXPENSES: "EXPENSES",
  INCOME: "INCOME",
  USERS: "USERS",
  CLIENTS: "CLIENTS",
};
export default menu;
