import actionTypes from "../constants/types";

const initialState = {
  total_members: null,
  total_contributions: null,
  total_loans: null,
  total_donations: null,
  total_expenses: null,
  total_incomes: null,
  contributions_summary: null,
  loans_summary: null,
  donations_summary: null,
  expenses_summary: null,
  incomes_summary: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_TOTALS:
      return {
        ...state,
        total_members: action.payload.members,
        total_contributions: action.payload.contributions,
        total_loans: action.payload.loans,
        total_donations: action.payload.donations,
        total_expenses: action.payload.expenses,
        total_incomes: action.payload.incomes,
      };
    case actionTypes.GET_YEARLY_SUMMARY:
      return {
        ...state,
        contributions_summary: action.payload.contributions,
        loans_summary: action.payload.loans,
        donations_summary: action.payload.donations,
        expenses_summary: action.payload.expenses,
        incomes_summary: action.payload.incomes,
      };
    default:
      return state;
  }
}
