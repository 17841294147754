import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/layout/Header";
import AsideMenu from "./components/layout/AsideMenu";
import Footer from "./components/layout//Footer";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true ? (
          <React.Fragment>
            <Header />
            <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
              <AsideMenu />
              <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <Component {...props} />
              </div>
            </div>
            <Footer />
          </React.Fragment>
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
