import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_income_summary,
  export_all_incomes,
} from "../../actions/incomes-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { incomeOptions } from "../../constants/mock";
import { SummaryColumns } from "../common/TableColumns";

const Incomes = () => {
  const ir = useSelector((state) => state.incomes);
  const dispatch = useDispatch();
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.INCOME)
    );
    dispatch(get_income_summary());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { summary, loading } = ir;
    if (!isEmpty(summary)) {
      setSummary(summary);
    }
    setIsLoading(loading);
  }, [ir]);

  const onExportClick = (month, year) => {
    export_all_incomes(month, year);
  };

  const tableColumns = SummaryColumns(
    onExportClick,
    "incomes",
    "total-incomes"
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(summary).length === 0) {
      content = (
        <EmptyBox
          link={"/incomes/create"}
          btnMessage={"Add Income"}
          icon={"fa fa-bitcoin icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No income has been added"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={summary} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Income"} options={incomeOptions} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Income Summary" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col mb-3">
                <span className="pr-3">
                  <Link
                    to="/incomes/office-incomes"
                    className="btn btn-info text-white"
                  >
                    View Office Revenue
                  </Link>
                </span>
                <span>
                  <Link
                    to="/incomes/members-incomes"
                    className="btn btn-success text-white"
                  >
                    View Members Incomes
                  </Link>
                </span>
              </div>

              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Incomes;
