import actionTypes from "../constants/types";

const initialState = {
  summary: [],
  loans: [],
  loan: {},
  loan_settings: [],
  loan_setting: {},
  payments: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_LOANS_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case actionTypes.GET_LOANS:
      return {
        ...state,
        loans: action.payload,
      };
    case actionTypes.GET_LOAN:
      return {
        ...state,
        loan: action.payload,
      };
    case actionTypes.GET_LOAN_SETTINGS:
      return {
        ...state,
        loan_settings: action.payload,
      };
    case actionTypes.GET_LOAN_SETTING:
      return {
        ...state,
        loan_setting: action.payload,
      };
    case actionTypes.GET_LOAN_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    default:
      return state;
  }
}
