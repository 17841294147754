import { combineReducers } from "redux";
import actionTypes from "../constants/types";
import authReducer from "./auth-reducer";
import flashReducer from "./flash-reducer";
import loadingReducer from "./loading-reducer";
import institutionReducer from "./institution-reducer";
import menuReducer from "./menu-reducer";
import modalReducer from "./modal-reducer";
import membersReducer from "./members-reducer";
import contributionsReducer from "./contributions-reducer";
import loansReducer from "./loans-reducer";
import expenseReducer from "./expenses-reducer";
import incomeReducer from "./incomes-reducer";
import donationReducer from "./donations-reducer";
import userReducer from "./users-reducer";
import adminReducer from "./admin-reducer";
import dashboardReducer from "./dashboard-reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  flash: flashReducer,
  loading: loadingReducer,
  institution: institutionReducer,
  menu: menuReducer,
  modal: modalReducer,
  members: membersReducer,
  contributions: contributionsReducer,
  loans: loansReducer,
  expenses: expenseReducer,
  incomes: incomeReducer,
  donations: donationReducer,
  users: userReducer,
  admin: adminReducer,
  dashboard: dashboardReducer,
});

export default (state, action) =>
  rootReducer(
    action.type === actionTypes.DESTROY_ALL_STATES ? undefined : state,
    action
  );
