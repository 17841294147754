import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner } from "../common";
import { get } from "../../actions/institution-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import Institute from "./Institute";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";

const Institution = () => {
  const insititute = useSelector((state) => state.institution);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [office, setOffice] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { user } = auth;
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.SETTINGS)
    );
    dispatch(get(user.institution_id));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { institution, loading } = insititute;
    if (!isEmpty(institution)) {
      setOffice(institution);
    }
    setIsLoading(loading);
  }, [insititute]);

  let content;
  if (isLoading && isEmpty(office)) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (isEmpty(office) && !isLoading) {
      content = (
        <EmptyBox
          link={"/institution/create"}
          btnMessage={"Create Institution"}
          icon={"fa fa-building-o icon-large"}
          btnIcon={"fa fa-plus"}
          info={"Make sure you set up an institution to go from there"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Institute institution={office} />
        </div>
      );
    }
  }

  const options = [
    {
      link: "institution/set-contribution",
      title: "Set Contribution",
      icon: "m-nav__link-icon fa fa-key",
    },
    {
      link: "expense-settings",
      title: "Expense Settings",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "income-settings",
      title: "Income Settings",
      icon: "m-nav__link-icon fa fa-bitcoin",
    },
    {
      link: "donation-settings",
      title: "Donation Settings",
      icon: "m-nav__link-icon fa fa-dollar",
    },
    {
      link: "loan-settings",
      title: "Loan Settings",
      icon: "m-nav__link-icon fa fa-euro",
    },
  ];

  return (
    <React.Fragment>
      <SubHeader title={"Institution"} options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Institution Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Institution;
