import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { create } from "../../actions/contributions-action";
import { gets } from "../../actions/members-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup, SelectListGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import isEmpty from "../../validation/isEmpty";
import { months, years } from "../../constants/mock";

const CreateContribution = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const mr = useSelector((state) => state.members);
  const [values, setValues] = useState({
    month: "",
    year: new Date().getFullYear(),
    staff_id: "",
    amount: "",
    payment_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const { payment_type } = auth.institution;

  useEffect(() => {
    dispatch(gets());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { members } = mr;
    if (!isEmpty(members)) {
      setMembers(members);
    }
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [mr, flash, loading]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      month: values.month,
      year: values.year,
      staff_id: values.staff_id,
      amount: values.amount,
      payment_type: payment_type,
    };
    dispatch(create(data, history));
  };

  const options = [
    {
      link: "contributions",
      title: "Members",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "contributions/upload",
      title: "Upload Contributions",
      icon: "m-nav__link-icon fa fa-upload",
    },
  ];

  let allMembers = [];
  if (!isEmpty(members)) {
    members.map((member) =>
      allMembers.push({
        label: `${member.member_name} | ${member.member_staff_id}`,
        value: member.member_staff_id,
      })
    );
  }

  return (
    <React.Fragment>
      <SubHeader options={options} title="Add Contribution" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Add  member contribution " />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <SelectListGroup
                        label="Month"
                        onChange={onChange}
                        value={values.month}
                        name="month"
                        options={months}
                      />
                    </div>
                    <div className="col-lg-6">
                      <SelectListGroup
                        label="Year"
                        onChange={onChange}
                        value={`${values.year}`}
                        name="year"
                        options={years}
                      />
                    </div>
                  </div>
                  {payment_type === "3" || payment_type === 3 ? (
                    <div className="form-group m-form__group row">
                      <div className="col-lg-6">
                        <SelectListGroup
                          label="Staff"
                          onChange={onChange}
                          value={`${values.staff_id}`}
                          name="staff_id"
                          options={allMembers}
                        />
                      </div>
                      <div className="col-lg-6">
                        <TextFieldGroup
                          label="Amount in GHS"
                          onChange={onChange}
                          value={values.amount}
                          name="amount"
                          placeholder="Enter amount member will pay every month"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="m-form__actions">
                    <Button
                      title="Add Contribution"
                      className="btn btn--black text-white"
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateContribution);
