import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { gets, update, delete_member } from "../../actions/members-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { membersOptions } from "../../constants/mock";
import Modal from "../layout/Modal";
import modalTypes from "../../constants/modal-types";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import { MembersTableColumns } from "../common/TableColumns";

const Members = () => {
  const allMembers = useSelector((state) => state.members);
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const load = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [values, setValues] = useState({
    name: "",
    staff_id: "",
    email: "",
    phone: "",
    amount: "",
    payment_type: "",
  });
  const [id, setId] = useState(null);
  const { payment_type } = auth.institution;
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.MEMBERS)
    );
    dispatch(gets());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const member = {
      name: values.name,
      staff_id: values.staff_id,
      email: values.email,
      phone: values.phone,
      amount: values.amount,
      payment_type: payment_type,
    };
    dispatch(update(id, member));
  };

  useEffect(() => {
    const { members, loading } = allMembers;
    const { success, message } = flash;
    const { isLoading } = load;
    if (!isEmpty(members)) {
      setMembers(members);
    }
    setIsLoading(loading);

    if (!isEmpty(id) && !isEmpty(members)) {
      const member = members.find((m) => m.id === id);
      setValues({
        name: member.member_name,
        staff_id: member.member_staff_id,
        email: !isEmpty(member.member_email) ? member.member_email : "",
        phone: !isEmpty(member.member_phone) ? member.member_phone : "",
        amount: !isEmpty(member.amount) ? member.amount : "",
      });
    }
    setButtonLoading(isLoading);
    displayToast(message, success);
  }, [allMembers, id, flash, load]);

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    setId(id);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onDeleteClick = useCallback(
    (id) => {
      dispatch(delete_member(id));
    },
    [dispatch]
  );

  const tableColumns = MembersTableColumns(
    onDeleteClick,
    modalToShow,
    modalTypes.EDIT_MEMBER
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(members).length === 0) {
      content = (
        <EmptyBox
          link={"/members/create"}
          btnMessage={"Add Member"}
          icon={"flaticon-users icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No member is registered"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={members} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Members"} options={membersOptions} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Members Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.EDIT_MEMBER ? (
        <Modal show={showModal} onClose={closeModal} header="Edit Member">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Name of Member"
                    onChange={onChange}
                    value={values.name}
                    type="text"
                    name="name"
                    placeholder="Enter name of Member"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Staff ID"
                    type="text"
                    onChange={onChange}
                    value={values.staff_id}
                    name="staff_id"
                    placeholder="Enter Staff ID of member"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    type="text"
                    label="Email"
                    onChange={onChange}
                    value={values.email}
                    name="email"
                    placeholder="Enter Email of member"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Phone"
                    type="text"
                    onChange={onChange}
                    value={values.phone}
                    name="phone"
                    placeholder="Enter phone of member"
                  />
                </div>
              </div>
              {payment_type === 2 || payment_type === "2" ? (
                <div className="form-group m-form__group row">
                  <div className="col-lg-6">
                    <TextFieldGroup
                      label="Amount in GHS"
                      onChange={onChange}
                      type="text"
                      value={values.amount}
                      name="amount"
                      placeholder="Enter amount member will pay every month"
                    />
                  </div>
                </div>
              ) : null}
              <div className="m-form__actions">
                <Button
                  title="Update"
                  className={"btn btn--black text-white"}
                  loading={buttonLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default Members;
