import axios from "axios";
import { setAuthToken } from "../utils/set-auth-token";
import SecureLS from "secure-ls";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";
import isEmpty from "../validation/isEmpty";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });

export const get_clients = (year) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/admin/clients?year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_CLIENTS,
          response.data.description.clients
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const impersonate_client = (id) => (dispatch) => {
  if (window.confirm("Are you sure u want to switch to client?")) {
    axios
      .post(`${API_ENDPOINT}/admin/clients/impersonate/client`, id)
      .then((response) => {
        const { token } = response.data.description.auth;
        const { auth, institution, logo, admin_id } = response.data.description;

        ls.set("jwtToken", token);
        ls.set("user", auth);
        ls.set("institution", institution);
        ls.set("logo", logo);
        ls.set("admin_id", admin_id);
        if (!isEmpty(institution)) {
          ls.set("inst_name", institution.name);
        }

        setAuthToken(token);
        dispatch(generalPurposeAction(actionTypes.SET_CURRENT_USER, auth));
        dispatch(
          generalPurposeAction(actionTypes.SET_USER_INSTITUTION, institution)
        );
        dispatch(generalPurposeAction(actionTypes.SET_LOGO, logo));
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const impersonate_admin = (id) => (dispatch) => {
  if (window.confirm("Are you sure u want to return to admin page?")) {
    axios
      .post(`${API_ENDPOINT}/admin/clients/impersonate/admin`, id)
      .then((response) => {
        const { token } = response.data.description.auth;
        const { auth, institution, logo } = response.data.description;

        ls.set("jwtToken", token);
        ls.set("user", auth);
        ls.set("institution", institution);
        ls.set("logo", logo);
        ls.remove("inst_name");

        setAuthToken(token);
        dispatch(generalPurposeAction(actionTypes.SET_CURRENT_USER, auth));
        dispatch(
          generalPurposeAction(actionTypes.SET_USER_INSTITUTION, institution)
        );
        dispatch(generalPurposeAction(actionTypes.SET_LOGO, logo));
        window.location.href = "/admin/clients";
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const export_clients = (year = "") => {
  axios({
    url: `${API_ENDPOINT}/admin/clients/export?year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_clients.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};
