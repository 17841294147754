import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_donation_summary,
  export_all_donations,
} from "../../actions/donations-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import { donationOptions } from "../../constants/mock";
import actionTypes from "../../constants/types";
import { SummaryColumns } from "../common/TableColumns";

const Donations = () => {
  const dr = useSelector((state) => state.donations);
  const dispatch = useDispatch();
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.DONATIONS)
    );
    dispatch(get_donation_summary());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { summary, loading } = dr;
    if (!isEmpty(summary)) {
      setSummary(summary);
    }
    setIsLoading(loading);
  }, [dr]);

  const onExportClick = (month, year) => {
    export_all_donations(month, year);
  };

  const tableColumns = SummaryColumns(
    onExportClick,
    "donations",
    "total-donations"
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(summary).length === 0) {
      content = (
        <EmptyBox
          link={"/donations/create"}
          btnMessage={"Add Donation"}
          icon={"fa fa-dollar icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No donation has been added"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={summary} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Donations"} options={donationOptions} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Donations Summary" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col mb-3">
                <span className="pr-3">
                  <Link
                    to="/donations/office-donations"
                    className="btn btn-info text-white"
                  >
                    View Office Donations
                  </Link>
                </span>
                <span>
                  <Link
                    to="/donations/members-donations"
                    className="btn btn-success text-white"
                  >
                    View Members Donations
                  </Link>
                </span>
              </div>

              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Donations;
