import actionTypes from "../constants/types";

const initialState = {
  summary: [],
  incomes: [],
  income: {},
  income_settings: [],
  income_setting: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_INCOME_SETTINGS:
      return {
        ...state,
        income_settings: action.payload,
      };
    case actionTypes.GET_INCOME_SETTING:
      return {
        ...state,
        income_setting: action.payload,
      };
    case actionTypes.GET_INCOMES_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case actionTypes.GET_INCOMES:
      return {
        ...state,
        incomes: action.payload,
      };
    case actionTypes.GET_INCOME:
      return {
        ...state,
        income: action.payload,
      };
    default:
      return state;
  }
}
