import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SecureLS from "secure-ls";
import roles from "../../constants/roles";
import menuItems from "../../constants/menu";
import isEmpty from "../../validation/isEmpty";
import { impersonate_admin } from "../../actions/admin-action";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });

const AsideMenu = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const menu = useSelector((state) => state.menu);
  const { user } = auth;
  const [activeMenu, setActiveMenu] = useState(menuItems.DASHBOARD);

  const onImpersonate = () => {
    const id = {
      admin_id: ls.get("admin_id"),
    };
    dispatch(impersonate_admin(id));
  };

  useEffect(() => {
    const { activeMenu } = menu;
    setActiveMenu(activeMenu);
  }, [menu]);

  let links;

  switch (user.role) {
    case roles.SUPER:
      links = (
        <div
          id="m_ver_menu"
          className="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
          m-menu-vertical="1"
          m-menu-scrollable="0"
          m-menu-dropdown-timeout="500"
        >
          <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Clients</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.CLIENTS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/admin/clients" className="m-menu__link">
                <i className="m-menu__link-icon flaticon-users" />
                <span className="m-menu__link-text">Clients</span>
              </Link>
            </li>
          </ul>
        </div>
      );
      break;
    case roles.ADMIN:
      links = (
        <div
          id="m_ver_menu"
          className="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
          m-menu-vertical="1"
          m-menu-scrollable="0"
          m-menu-dropdown-timeout="500"
        >
          <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Dashboard</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.DASHBOARD ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/dashboard" className="m-menu__link">
                <i className="m-menu__link-icon flaticon-dashboard" />
                <span className="m-menu__link-text">Dashboard</span>
              </Link>
            </li>
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Settings</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.SETTINGS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/institution" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-cogs" />
                <span className="m-menu__link-text">Settings</span>
              </Link>
            </li>
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Features</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.MEMBERS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/members" className="m-menu__link ">
                <i className="m-menu__link-icon flaticon-profile" />
                <span className="m-menu__link-text">Members</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.CONTRIBUTIONS
                  ? "m-menu__item--active"
                  : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/contributions" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-bank" />
                <span className="m-menu__link-text">Contributions</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.EXPENSES ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/expenses" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-money" />
                <span className="m-menu__link-text">Expenses</span>
              </Link>
            </li>

            <li
              className={`m-menu__item ${
                activeMenu === menuItems.INCOME ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/incomes" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-bitcoin" />
                <span className="m-menu__link-text">Income</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.LOANS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/loans" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-euro" />
                <span className="m-menu__link-text">Loans</span>
              </Link>
            </li>

            <li
              className={`m-menu__item ${
                activeMenu === menuItems.DONATIONS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/donations" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-dollar" />
                <span className="m-menu__link-text">Donations</span>
              </Link>
            </li>
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Users</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.USERS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/users" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-users" />
                <span className="m-menu__link-text">Users</span>
              </Link>
            </li>
            {!isEmpty(ls.get("admin_id")) ? (
              <li
                className="m-menu__item"
                aria-haspopup="true"
                m-menu-link-redirect="1"
              >
                <Link to="" className="m-menu__link" onClick={onImpersonate}>
                  <i className="m-menu__link-icon fa fa-sign-out" />
                  <span className="m-menu__link-text">Return to Admin</span>
                </Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      );
      break;
    case roles.USER:
      links = (
        <div
          id="m_ver_menu"
          className="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
          m-menu-vertical="1"
          m-menu-scrollable="0"
          m-menu-dropdown-timeout="500"
        >
          <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Dashboard</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.DASHBOARD ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/dashboard" className="m-menu__link">
                <i className="m-menu__link-icon flaticon-dashboard" />
                <span className="m-menu__link-text">Dashboard</span>
              </Link>
            </li>
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Settings</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.SETTINGS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/institution" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-cogs" />
                <span className="m-menu__link-text">Settings</span>
              </Link>
            </li>
            <li className="m-menu__section m-menu__section--first">
              <h4 className="m-menu__section-text">Features</h4>
              <i className="m-menu__section-icon flaticon-more-v3" />
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.MEMBERS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/members" className="m-menu__link ">
                <i className="m-menu__link-icon flaticon-profile" />
                <span className="m-menu__link-text">Members</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.CONTRIBUTIONS
                  ? "m-menu__item--active"
                  : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/contributions" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-bank" />
                <span className="m-menu__link-text">Contributions</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.EXPENSES ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/expenses" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-money" />
                <span className="m-menu__link-text">Expenses</span>
              </Link>
            </li>

            <li
              className={`m-menu__item ${
                activeMenu === menuItems.INCOME ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/incomes" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-bitcoin" />
                <span className="m-menu__link-text">Income</span>
              </Link>
            </li>
            <li
              className={`m-menu__item ${
                activeMenu === menuItems.LOANS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/loans" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-euro" />
                <span className="m-menu__link-text">Loans</span>
              </Link>
            </li>

            <li
              className={`m-menu__item ${
                activeMenu === menuItems.DONATIONS ? "m-menu__item--active" : ""
              }`}
              aria-haspopup="true"
              m-menu-link-redirect="1"
            >
              <Link to="/donations" className="m-menu__link ">
                <i className="m-menu__link-icon fa fa-dollar" />
                <span className="m-menu__link-text">Donations</span>
              </Link>
            </li>
          </ul>
        </div>
      );
      break;
    default:
      links = "";
  }

  return (
    <div>
      <button
        className="m-aside-left-close  m-aside-left-close--skin-light "
        id="m_aside_left_close_btn"
      >
        <i className="la la-close" />
      </button>
      <div
        id="m_aside_left"
        className="m-grid__item	m-aside-left  m-aside-left--skin-light"
      >
        {links}
      </div>
    </div>
  );
};

export default AsideMenu;
