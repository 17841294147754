import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_clients,
  impersonate_client,
  export_clients,
} from "../../actions/admin-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { ClientsColumns } from "../common/TableColumns";
import { years } from "../../constants/mock";
import { SelectListGroup } from "../forms/auth";

const Users = () => {
  const today = new Date();
  const ar = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [clients, setClients] = useState([]);
  const [year, setYear] = useState(today.getFullYear());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.CLIENTS)
    );
    dispatch(get_clients(year));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeYear = (e) => {
    const { value } = e.target;
    setYear(value);
    dispatch(get_clients(value));
  };

  useEffect(() => {
    const { clients, loading } = ar;
    if (!isEmpty(clients)) {
      setClients(clients);
    }
    setIsLoading(loading);
  }, [ar]);

  const onImpersonateClient = useCallback(
    (userId) => {
      const id = {
        user_id: userId,
      };
      dispatch(impersonate_client(id));
    },
    [dispatch]
  );

  const onExportClick = () => {
    export_clients(year);
  };

  const tableColumns = ClientsColumns(onImpersonateClient);

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(clients).length === 0) {
      content = (
        <EmptyBox
          link={""}
          icon={"flaticon-users icon-large"}
          btnMessage={"Add Client"}
          btnIcon={"fa fa-plus"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={clients} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Clients" options={null} />
      <div className="m-content">
        <div className="form-group m-form__group row">
          <div className="col-lg-5">
            <SelectListGroup
              onChange={onChangeYear}
              value={year}
              name="year"
              options={years}
            />
          </div>
        </div>
        <div className="m-portlet">
          <PortletHead title="Client Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {!isEmpty(clients) ? (
                <div className="col mb-3">
                  <span className="pr-3">
                    <button
                      className="btn btn--orange text-white"
                      onClick={() => onExportClick()}
                    >
                      <i className="fa fa-download"></i>Download Excel{" "}
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </span>
                </div>
              ) : null}
              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Users;
