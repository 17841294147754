import React from "react";
import { Spin } from "./Spin";

const Widget = ({
  title,
  color,
  value,
  loading,
  percentage,
  progress,
  description,
}) => {
  return (
    <div className="col-md-2 col-lg-2 col-sm-2">
      <div className="m-widget24">
        <div className="m-widget24__item">
          {!loading ? (
            <>
              <h4 className="m-widget24__title">{title}</h4>
              <br />
              <span className="m-widget24__desc">{description}</span>
              <span className={`m-widget24__stats ${color}`}>{value}</span>
              <div className="m--space-10" />
              <div className="progress m-progress--sm">
                <div
                  className={`progress-bar ${progress}`}
                  role="progressbar"
                  style={{ width: `${percentage}%` }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <span className="m-widget24__number">
                {percentage} {"%"}
              </span>
            </>
          ) : (
            <Spin />
          )}
        </div>
      </div>
    </div>
  );
};

export { Widget };
