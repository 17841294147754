import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CustomSelect = ({
  options,
  onChange,
  label,
  name,
  value,
  defaultValue,
}) => {
  const animatedComponents = makeAnimated();
  return (
    <React.Fragment>
      <label>{label}</label>
      <Select
        className={"form-control m-input"}
        name={name}
        value={value}
        onChange={onChange}
        options={options}
        components={animatedComponents}
        isSearchable={true}
        defaultValue={defaultValue}
      />
    </React.Fragment>
  );
};

export { CustomSelect };
