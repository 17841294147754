import React from "react";

const Pagination = ({
  gotoPage,
  canPreviousPage,
  pageIndex,
  pageSize,
  nextPage,
  pageOptions,
  onChangeInInput,
  onChangeInSelect,
  canNextPage,
  pageCount,
  previousPage,
}) => {
  return (
    <div className="row" style={{ textAlign: "center" }}>
      <div className="col-md-3">
        <button
          className="btn btn--black"
          style={{ color: "#FFF" }}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <i className="fa fa-angle-double-left"></i>
        </button>
        <button
          className="btn btn--black"
          style={{ color: "#FFF" }}
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <i className="fa fa-angle-left"></i>
        </button>
      </div>
      <div className="col-md-2" style={{ marginTop: 7 }}>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </div>
      <div className="col-md-2">
        <input
          type="number"
          min={1}
          style={{ width: "100px" }}
          max={pageOptions.length}
          defaultValue={pageIndex + 1}
          onChange={onChangeInInput}
          className="form-control m-input"
        />
      </div>
      <div className="col-md-2">
        <select
          value={pageSize}
          onChange={onChangeInSelect}
          className="form-control m-input"
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="col-md-3">
        <button
          className="btn btn--black"
          style={{ color: "#FFF" }}
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <i className="fa fa-angle-right"></i>
        </button>
        <button
          className="btn btn--black"
          style={{ color: "#FFF" }}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <i className="fa fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  );
};

export { Pagination };
