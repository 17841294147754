import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { gets, update, get_payments } from "../../actions/loans-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import { SelectListGroup, Button, TextFieldGroup } from "../forms/auth";
import {
  TotalLoanColumns,
  LoanPaymentsMembersColumn,
} from "../common/TableColumns";
import { displayToast } from "../common/Toaster";
import { months, years } from "../../constants/mock";

const TotalLoans = ({ match }) => {
  const today = new Date();
  const lr = useSelector((state) => state.loans);
  const fr = useSelector((state) => state.flash);
  const ld = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [payments, setPayments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [id, setId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    staff_id: "",
    amount_paid: "",
    amount_loaned: "",
    interest: "",
  });
  const [input, setInput] = useState({
    month: today.toLocaleString("default", { month: "long" }),
    year: today.getFullYear(),
  });

  useEffect(() => {
    const { params } = match;
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.LOANS)
    );
    dispatch(gets(params.month, params.year));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    dispatch(gets(input.month, input.year));
  };

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    if (type === modalTypes.VIEW_LOAN_PAYMENTS) {
      dispatch(get_payments(id));
    }
    setId(id);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const loan = {
      password: values.password,
      staff_id: values.staff_id,
      amount_paid: values.amount_paid,
    };
    dispatch(update(id, loan));
    setValues({
      password: "",
      staff_id: "",
      amount_paid: "",
      amount_loaned: "",
      interest: "",
    });
  };

  useEffect(() => {
    const { loans, loading, payments } = lr;
    const { success, message } = fr;
    const { isLoading } = ld;
    setLoans(loans);
    setIsLoading(loading);
    setPayments(payments);

    if (!isEmpty(id) && !isEmpty(loans)) {
      const loan = loans.find((l) => l.id === id);
      setValues({
        password: "",
        staff_id: loan.member_staff_id,
        amount_paid: "",
        amount_loaned: loan.amount_loaned,
        interest: loan.interest,
      });
    }
    setButtonLoading(isLoading);
    displayToast(message, success);
  }, [lr, fr, id, ld]);

  const options = [
    { link: "loans", title: "Loans", icon: "m-nav__link-icon fa fa-eye" },
    {
      link: "loans/create",
      title: "Grant Loan",
      icon: "m-nav__link-icon fa fa-plus",
    },
  ];

  const tableColumns = TotalLoanColumns(modalToShow, modalTypes);
  const loanPaymentsColumns = LoanPaymentsMembersColumn();

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(loans).length > 0) {
      content = (
        <div className="col-md-12">
          <Table data={loans} columns={tableColumns} />
        </div>
      );
    } else {
      content = (
        <EmptyBox
          link={"/loans/create"}
          btnMessage={"Grant Loan"}
          icon={"fa fa-dollar icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No Records Found"}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Total Loans" options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Loans" />
          <form
            className="m-form m-form--fit m-form--label-align-right"
            onSubmit={onSubmitSearch}
          >
            <div className="form-group m-form__group row">
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChangeInput}
                  value={input.month}
                  name="month"
                  options={months}
                />
              </div>
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChangeInput}
                  value={input.year}
                  name="year"
                  options={years}
                />
              </div>
              <div className="col-lg-2" style={{ marginTop: "20px" }}>
                <Button title="Search" className="btn btn--black text-white" />
              </div>
            </div>
          </form>
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.MAKE_LOAN_PAYMENT ? (
        <Modal show={showModal} onClose={closeModal} header="Pay Loan">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Your Password"
                    onChange={onChange}
                    value={values.password}
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Staff ID"
                    type="text"
                    onChange={onChange}
                    value={values.staff_id}
                    name="staff_id"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Amount Loaned (GHS)"
                    type="text"
                    onChange={onChange}
                    value={values.amount_loaned}
                    name="amount_returned"
                    disabled="disabled"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Interest (GHS)"
                    type="text"
                    onChange={onChange}
                    value={values.interest}
                    name="interest"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Current Payment (GHS)"
                    type="text"
                    onChange={onChange}
                    value={values.amount_paid}
                    name="amount_paid"
                    placeholder="Enter amount you are paying"
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title="Pay Loan"
                  className="btn btn--black text-white"
                  loading={buttonLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
      {showModal && modalType === modalTypes.VIEW_LOAN_PAYMENTS ? (
        <Modal show={showModal} onClose={closeModal} header="Loan Payments">
          <div className="col-md-12">
            <Table data={payments} columns={loanPaymentsColumns} />
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default TotalLoans;
