import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { gets } from "../../actions/contributions-action";
import { generalPurposeAction } from "../../actions/common-action";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { SelectListGroup, Button } from "../forms/auth";
import { TotalContributionsColumns } from "../common/TableColumns";
import { months, years } from "../../constants/mock";

const TotalContributions = ({ match }) => {
  const cr = useSelector((state) => state.contributions);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [contributions, setContributions] = useState([]);
  const [values, setValues] = useState({ month: "", year: "" });

  useEffect(() => {
    const { params } = match;
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.CONTRIBUTIONS)
    );
    dispatch(gets(params.month, params.year));
    setValues({ month: params.month, year: params.year });
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(gets(values.month, values.year));
  };

  useEffect(() => {
    const { contributions, loading } = cr;
    setContributions(contributions);
    setIsLoading(loading);
  }, [cr]);

  const options = [
    {
      link: "contributions",
      title: "Contributions",
      icon: "m-nav__link-icon fa fa-eye",
    },
    {
      link: "contributions/create",
      title: "Add Contribution",
      icon: "m-nav__link-icon fa fa-plus",
    },
    {
      link: "contributions/upload",
      title: "Upload Contributions",
      icon: "m-nav__link-icon fa fa-upload",
    },
  ];

  const tableColumns = TotalContributionsColumns();

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(contributions).length > 0) {
      content = (
        <div className="col-md-12">
          <Table data={contributions} columns={tableColumns} />
        </div>
      );
    } else {
      content = (
        <EmptyBox
          link={"/contributions/create"}
          btnMessage={"Add Contribution"}
          icon={"fa fa-bank icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No Records Found"}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Total Contributions" options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Contributions" />
          <form
            className="m-form m-form--fit m-form--label-align-right"
            onSubmit={onSubmit}
          >
            <div className="form-group m-form__group row">
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.month}
                  name="month"
                  options={months}
                />
              </div>
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.year}
                  name="year"
                  options={years}
                />
              </div>
              <div className="col-lg-2" style={{ marginTop: "20px" }}>
                <Button title="Search" className="btn btn--black text-white" />
              </div>
            </div>
          </form>
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TotalContributions;
