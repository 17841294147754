import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generalPurposeAction } from "../../actions/common-action";
import { change_password } from "../../actions/auth-action";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import actionTypes from "../../constants/types";
import { displayToast } from "../common/Toaster";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const passwordData = {
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    };
    dispatch(change_password(passwordData));
    setValues({ old_password: "", new_password: "", confirm_password: "" });
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  const options = [
    { link: "profile", title: "Profile", icon: "m-nav__link-icon fa fa-user" },
  ];

  return (
    <div>
      <SubHeader options={options} title="Change Password" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Change your password" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Old Password"
                        onChange={onChange}
                        value={values.old_password}
                        name="old_password"
                        type="password"
                        placeholder="Old password"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="New Password"
                        onChange={onChange}
                        value={values.new_password}
                        name="new_password"
                        type="password"
                        placeholder="New password"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Confirm New Password"
                        onChange={onChange}
                        value={values.confirm_password}
                        name="confirm_password"
                        type="password"
                        placeholder="Confirm new password"
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title={"Change Password"}
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
