import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const gets = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/users`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_USERS,
          response.data.description.users
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/users/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_USER,
          response.data.description.user
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const create = (user, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/users`, user)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/users");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update = (id, user) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/users/${id}`, user)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_USERS,
          response.data.description.users
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const revoke_access = (id) => (dispatch) => {
  if (window.confirm("Are you sure u want to revoke access to user?")) {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    axios
      .get(`${API_ENDPOINT}/users/revoke-user-access/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_USERS,
            response.data.description.users
          )
        );
      })
      .catch((error) => {
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};

export const grant_access = (id) => (dispatch) => {
  if (window.confirm("Are you sure u want to grant access to user?")) {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    axios
      .get(`${API_ENDPOINT}/users/grant-user-access/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_USERS,
            response.data.description.users
          )
        );
      })
      .catch((error) => {
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};
