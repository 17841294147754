import React from "react";

const Title = ({ title }) => {
  return (
    <div className="m-login__head">
      <h3 className="m-login__title">{title}</h3>
    </div>
  );
};

export { Title };
