import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { create } from "../../actions/users-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { usersOptions } from "../../constants/mock";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";

const CreateUser = ({ history }) => {
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      name: values.name,
      email: values.email,
      phone: values.phone,
    };
    dispatch(create(user, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading]);

  return (
    <React.Fragment>
      <SubHeader options={usersOptions} title="Create User" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Add User to system" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Name of User"
                        onChange={onChange}
                        value={values.name}
                        type="text"
                        name="name"
                        placeholder="Enter name of User"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="email"
                        type="text"
                        onChange={onChange}
                        value={values.email}
                        name="email"
                        placeholder="Enter email of user"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Phone"
                        type="text"
                        onChange={onChange}
                        value={values.phone}
                        name="phone"
                        placeholder="Enter phone of user"
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title="Submit"
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateUser);
