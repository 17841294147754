import React from "react";
import { Modal as M } from "react-bootstrap";

const Modal = ({ show, children, onClose, header }) => {
  return (
    <>
      <M
        show={show}
        onHide={onClose}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <M.Header closeButton>
          <M.Title>{header}</M.Title>
        </M.Header>
        <M.Body>{children}</M.Body>
        <M.Footer>
          <button className="btn btn--black text-white" onClick={onClose}>
            Close
          </button>
        </M.Footer>
      </M>
    </>
  );
};

export default Modal;
