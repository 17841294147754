import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { upload } from "../../actions/contributions-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { SubHeader, PortletHead } from "../common";
import {
  Button,
  TextFieldGroup,
  SelectListGroup,
  FileInputGroup,
} from "../forms/auth";
import { displayToast } from "../common/Toaster";
import { months, years } from "../../constants/mock";

const TEMPLATE_URL = "http://welfare.test/files/default_contributions.xlsx";

const UploadContributions = ({ history }) => {
  const today = new Date();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    month: today.toLocaleString("default", { month: "long" }),
    year: today.getFullYear(),
    sheet: "",
    start_row: "",
    payment_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [excel, setExcel] = useState("");
  const { payment_type } = auth.institution;

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setExcel(e.target.files[0]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("excel", excel, excel.name);
    fd.append("month", values.month);
    fd.append("year", values.year);
    fd.append("sheet", values.sheet);
    fd.append("start_row", values.start_row);
    fd.append("payment_type", payment_type);
    dispatch(upload(fd, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading]);

  const options = [
    {
      link: "/contributions",
      title: "Contributions",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "/contributions/create",
      title: "Add Contribution",
      icon: "m-nav__link-icon fa fa-user",
    },
  ];

  return (
    <React.Fragment>
      <SubHeader options={options} title="Upload Contributions" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Upload contributions" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <a
                        href={TEMPLATE_URL}
                        className="btn btn--orange text-white btn-lg"
                      >
                        <i className="fa fa-file-excel-o" /> Download Sample
                        Template
                      </a>
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <FileInputGroup
                        accept=".xls, .xlsx, .csv"
                        label="Excel File (Must be .xls, .xlsx or .csv file)"
                        name="excel"
                        id="customFile"
                        type="file"
                        onChange={handleFileUpload}
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <SelectListGroup
                        label="Month"
                        onChange={onChange}
                        value={values.month}
                        name="month"
                        options={months}
                      />
                    </div>
                    <div className="col-lg-6">
                      <SelectListGroup
                        label="Year"
                        onChange={onChange}
                        value={values.year}
                        name="year"
                        options={years}
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Sheet Number"
                        onChange={onChange}
                        value={values.sheet}
                        name="sheet"
                        placeholder="Eg 1"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Start Row"
                        onChange={onChange}
                        value={values.start_row}
                        name="start_row"
                        placeholder="Eg 2"
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title={"Upload Contributions"}
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UploadContributions);
