import React from "react";

const PortletHead = ({ title }) => {
  return (
    <div className="m-portlet__head">
      <div className="m-portlet__head-caption">
        <div className="m-portlet__head-title">
          <span className="m-portlet__head-icon m--hide">
            <i className="la la-gear" />
          </span>
          <h3 className="m-portlet__head-text">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export { PortletHead };
