import React from "react";
import { Link } from "react-router-dom";

const Terms = ({ name, value, type, onChange }) => {
  return (
    <div className="row form-group m-form__group m-login__form-sub">
      <div className="col m--align-left">
        <label className="m-checkbox m-checkbox--focus">
          <input
            type="checkbox"
            className="form-check-input"
            name={name}
            value={value}
            onChange={onChange}
          />
          I Agree the&nbsp;
          <Link
            to="#"
            className="m-link m-link--focus"
            style={{ color: "#ff7738" }}
          >
            terms and conditions
          </Link>
          .
          <span />
        </label>
        <span className="m-form__help" />
      </div>
    </div>
  );
};

export { Terms };
