import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { create_loan_setting } from "../../actions/loans-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import menuTypes from "../../constants/menu";

const CreateLoanSetting = ({ history }) => {
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    type: "",
    rate: "",
    min_month: "",
    max_month: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.SETTINGS)
    );
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const setting = {
      type: values.type,
      rate: values.rate,
      min_month: values.min_month,
      max_month: values.max_month,
    };
    dispatch(create_loan_setting(setting, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading]);

  const options = [
    {
      link: "loan-settings",
      title: "Loan Settings",
      icon: "m-nav__link-icon fa fa-cogs",
    },
    {
      link: "institution/set-contribution",
      title: "Set Contribution",
      icon: "m-nav__link-icon fa fa-key",
    },
    {
      link: "expense-settings",
      title: "Expense Settings",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "income-settings",
      title: "Income Settings",
      icon: "m-nav__link-icon fa fa-bitcoin",
    },
    {
      link: "donation-settings",
      title: "Donation Settings",
      icon: "m-nav__link-icon fa fa-dollar",
    },
    {
      link: "loan-settings",
      title: "Loan Settings",
      icon: "m-nav__link-icon fa fa-euro",
    },
  ];

  return (
    <React.Fragment>
      <SubHeader options={options} title="Set Loan" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Loan Setup" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Type of Loan"
                        type="text"
                        onChange={onChange}
                        value={values.type}
                        name="type"
                        placeholder="Enter type of loan eg Funeral"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Rate of interest"
                        type="text"
                        onChange={onChange}
                        value={values.rate}
                        name="rate"
                        placeholder="Enter the rate of interest eg. 0.05"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Minimum month"
                        onChange={onChange}
                        value={values.min_month}
                        name="min_month"
                        type="number"
                        min="1"
                        placeholder="Enter minimum month as time for interest"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Maximum month"
                        onChange={onChange}
                        value={values.max_month}
                        name="max_month"
                        type="number"
                        min="1"
                        placeholder="Enter maximum month as time for interest"
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title="Set Loan"
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateLoanSetting);
