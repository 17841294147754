import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_loan_settings,
  delete_loan_setting,
  update_loan_setting,
} from "../../actions/loans-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import { LoanSettingsColumns } from "../common/TableColumns";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";

const LoanSettings = () => {
  const lr = useSelector((state) => state.loans);
  const fr = useSelector((state) => state.flash);
  const ld = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [id, setId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [values, setValues] = useState({
    type: "",
    rate: "",
    min_month: "",
    max_month: "",
  });

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.SETTINGS)
    );
    dispatch(get_loan_settings());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { loan_settings, loading } = lr;
    const { success, message } = fr;
    const { isLoading } = ld;
    if (!isEmpty(loan_settings)) {
      setSettings(loan_settings);
    }
    setIsLoading(loading);

    if (!isEmpty(id) && !isEmpty(loan_settings)) {
      const setting = loan_settings.find((s) => s.id === id);
      setValues({
        type: setting.type,
        rate: setting.rate,
        min_month: setting.min_month,
        max_month: setting.max_month,
      });
    }
    setButtonLoading(isLoading);
    displayToast(message, success);
  }, [lr, fr, id, ld]);

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    setId(id);
  };

  const onDeleteClick = (id) => {
    dispatch(delete_loan_setting(id));
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const setting = {
      type: values.type,
      rate: values.rate,
      min_month: values.min_month,
      max_month: values.max_month,
    };
    dispatch(update_loan_setting(id, setting));
  };

  const options = [
    {
      link: "loan-settings/create",
      title: "Set Loan",
      icon: "m-nav__link-icon fa fa-plus",
    },
    {
      link: "institution/set-contribution",
      title: "Set Contribution",
      icon: "m-nav__link-icon fa fa-key",
    },
    {
      link: "expense-settings",
      title: "Expense Settings",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "income-settings",
      title: "Income Settings",
      icon: "m-nav__link-icon fa fa-bitcoin",
    },
    {
      link: "donation-settings",
      title: "Donation Settings",
      icon: "m-nav__link-icon fa fa-dollar",
    },
    {
      link: "loan-settings",
      title: "Loan Settings",
      icon: "m-nav__link-icon fa fa-euro",
    },
  ];

  const tableColumns = LoanSettingsColumns(
    modalToShow,
    modalTypes.EDIT_LOAN_SETTING,
    onDeleteClick
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(settings).length === 0) {
      content = (
        <EmptyBox
          link={"/loan-settings/create"}
          btnMessage={"Set Loan"}
          icon={"fa fa-cogs icon-large"}
          btnIcon={"fa fa-plus"}
          info={"You have not set any loan"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={settings} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Loan Settings"} options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Loan Settings" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.EDIT_LOAN_SETTING ? (
        <Modal onClose={closeModal} header={"Edit Loan Setting"}>
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Type of Loan"
                    type="text"
                    onChange={onChange}
                    value={values.type}
                    name="type"
                    placeholder="Enter type of loan eg Funeral"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Rate of interest"
                    type="text"
                    onChange={onChange}
                    value={values.rate}
                    name="rate"
                    placeholder="Enter the rate of interest eg. 0.05"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Minimum month"
                    onChange={onChange}
                    value={`${values.min_month}`}
                    name="min_month"
                    type="number"
                    min="1"
                    placeholder="Enter minimum month as time for interest"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Maximum month"
                    onChange={onChange}
                    value={`${values.max_month}`}
                    name="max_month"
                    type="number"
                    min="1"
                    placeholder="Enter maximum month as time for interest"
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title={"Update Loan Setting"}
                  className={"btn btn--black text-white"}
                  loading={buttonLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default LoanSettings;
