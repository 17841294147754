import React from "react";

const FileInputGroup = ({
  label,
  name,
  accept,
  info,
  type,
  onChange,
  id,
  multiple,
  disabled,
}) => {
  return (
    <React.Fragment>
      <label>{label}</label>
      <input
        type={type}
        className={"form-control"}
        id={id}
        name={name}
        accept={accept}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
        required={"required"}
      />
      {info && <span className="m-form__help">{info}</span>}
    </React.Fragment>
  );
};

export { FileInputGroup };
