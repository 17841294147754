import React from "react";
import { Link } from "react-router-dom";

const RedirectLinks = ({ title, link, className }) => {
  return (
    <React.Fragment>
      <Link to={link} className={className}>
        {title}
      </Link>
    </React.Fragment>
  );
};

export { RedirectLinks };
