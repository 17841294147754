const modalTypes = {
  EDIT_INST: "EDIT_INST",
  SET_LOGO: "SET_LOGO",
  EDIT_MEMBER: "EDIT_MEMBER",
  NO_CONTRIBUTION: "NO_CONTRIBUTION",
  EDIT_LOAN_SETTING: "EDIT_LOAN_SETTING",
  CREATE_LOAN: "CREATE_LOAN",
  VIEW_LOAN_PAYMENTS: "VIEW_LOAN_PAYMENTS",
  MAKE_LOAN_PAYMENT: "MAKE_LOAN_PAYMENT",
  VIEW_EXPENSE: "VIEW_EXPENSE",
  VIEW_INCOME: "VIEW_INCOME",
  VIEW_DONATION: "VIEW_DONATION",
  VIEW_MEMBER_CONTRIBUTIONS: "VIEW_MEMBER_CONTRIBUTIONS",
  VIEW_MEMBER_LOANS: "VIEW_MEMBER_LOANS",
  VIEW_MEMBER_EXPENSES: "VIEW_MEMBER_EXPENSES",
  VIEW_MEMBER_DONATIONS: "VIEW_MEMBER_DONATIONS",
  VIEW_MEMBER_INCOME: "VIEW_MEMBER_INCOME",
  EDIT_USER: "EDIT_USER",
};
export default modalTypes;
