import actionTypes from "../constants/types";
import menu from "../constants/menu";

const initialState = {
  activeMenu: menu.DASHBOARD,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
      };
    default:
      return state;
  }
}
