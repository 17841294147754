const months = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

const years = [
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
];

const donationOptions = [
  {
    link: "donations",
    title: "Donations",
    icon: "m-nav__link-icon fa fa-dollar",
  },
  {
    link: "donations/office-donations",
    title: "Office Donations",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "donations/members-donations",
    title: "Members Donations",
    icon: "m-nav__link-icon fa fa-users",
  },
  {
    link: "donations/create",
    title: "Add Donation",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "donations/upload",
    title: "Upload DOnations",
    icon: "m-nav__link-icon fa fa-file-excel-o",
  },
  {
    link: "donation-settings",
    title: "Donation Settings",
    icon: "m-nav__link-icon fa fa-cogs",
  },
];

const expenseOptions = [
  {
    link: "expenses",
    title: "Expenses",
    icon: "m-nav__link-icon fa fa-money",
  },
  {
    link: "expenses/office-expenses",
    title: "Office Expenses",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "expenses/members-expenses",
    title: "Members Expenses",
    icon: "m-nav__link-icon fa fa-users",
  },
  {
    link: "expenses/create",
    title: "Add Expense",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "expenses/upload",
    title: "Upload Expenses",
    icon: "m-nav__link-icon fa fa-file-excel-o",
  },
  {
    link: "expense-settings",
    title: "Expense Settings",
    icon: "m-nav__link-icon fa fa-cogs",
  },
];

const incomeOptions = [
  {
    link: "incomes",
    title: "Incomes",
    icon: "m-nav__link-icon fa fa-bitcoin",
  },
  {
    link: "incomes/office-incomes",
    title: "Office Revenues",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "incomes/members-incomes",
    title: "Members Incomes",
    icon: "m-nav__link-icon fa fa-users",
  },
  {
    link: "incomes/create",
    title: "Add Income",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "incomes/upload",
    title: "Upload Incomes",
    icon: "m-nav__link-icon fa fa-file-excel-o",
  },
  {
    link: "income-settings",
    title: "Income Settings",
    icon: "m-nav__link-icon fa fa-cogs",
  },
];

const expenseSettingsOptions = [
  {
    link: "expense-settings/create",
    title: "Add Expense Type",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "institution",
    title: "Settings",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "institution/set-contribution",
    title: "Set Contribution",
    icon: "m-nav__link-icon fa fa-key",
  },
  {
    link: "expense-settings",
    title: "Expense Settings",
    icon: "m-nav__link-icon fa fa-money",
  },
  {
    link: "income-settings",
    title: "Income Settings",
    icon: "m-nav__link-icon fa fa-bitcoin",
  },
  {
    link: "donation-settings",
    title: "Donation Settings",
    icon: "m-nav__link-icon fa fa-dollar",
  },
  {
    link: "loan-settings",
    title: "Loan Settings",
    icon: "m-nav__link-icon fa fa-euro",
  },
];

const incomeSettingsOptions = [
  {
    link: "income-settings/create",
    title: "Add Income Type",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "institution",
    title: "Settings",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "institution/set-contribution",
    title: "Set Contribution",
    icon: "m-nav__link-icon fa fa-key",
  },
  {
    link: "expense-settings",
    title: "Expense Settings",
    icon: "m-nav__link-icon fa fa-money",
  },
  {
    link: "income-settings",
    title: "Income Settings",
    icon: "m-nav__link-icon fa fa-bitcoin",
  },
  {
    link: "donation-settings",
    title: "Donation Settings",
    icon: "m-nav__link-icon fa fa-dollar",
  },
  {
    link: "loan-settings",
    title: "Loan Settings",
    icon: "m-nav__link-icon fa fa-euro",
  },
];

const donationSettingsOptions = [
  {
    link: "donation-settings/create",
    title: "Add Donation Type",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "institution",
    title: "Settings",
    icon: "m-nav__link-icon fa fa-building",
  },
  {
    link: "institution/set-contribution",
    title: "Set Contribution",
    icon: "m-nav__link-icon fa fa-key",
  },
  {
    link: "expense-settings",
    title: "Expense Settings",
    icon: "m-nav__link-icon fa fa-money",
  },
  {
    link: "income-settings",
    title: "Income Settings",
    icon: "m-nav__link-icon fa fa-bitcoin",
  },
  {
    link: "donation-settings",
    title: "Donation Settings",
    icon: "m-nav__link-icon fa fa-dollar",
  },
  {
    link: "loan-settings",
    title: "Loan Settings",
    icon: "m-nav__link-icon fa fa-euro",
  },
];

const membersOptions = [
  {
    link: "members",
    title: "Members",
    icon: "m-nav__link-icon fa fa-users",
  },
  {
    link: "members/create",
    title: "Add Member",
    icon: "m-nav__link-icon fa fa-plus",
  },
  {
    link: "members/upload",
    title: "Upload Members",
    icon: "m-nav__link-icon fa fa-upload",
  },
];

const usersOptions = [
  {
    link: "users",
    title: "Users",
    icon: "m-nav__link-icon flaticon-users",
  },
  {
    link: "users/create",
    title: "Add User",
    icon: "m-nav__link-icon fa fa-plus",
  },
];

export {
  months,
  years,
  donationOptions,
  expenseOptions,
  incomeOptions,
  expenseSettingsOptions,
  incomeSettingsOptions,
  donationSettingsOptions,
  membersOptions,
  usersOptions,
};
