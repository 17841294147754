import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const get_donation_settings = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/donation-settings`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATION_SETTINGS,
          response.data.description.donation_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_donation_setting = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/donation-settings/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATION_SETTING,
          response.data.description.donation_setting
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const get_donation_settings_with_parent = (parentId) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/donation-settings/parent?parent_id=${parentId}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATION_SETTINGS,
          response.data.description.donation_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const create_donation_setting = (setting, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/donation-settings`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/donation-settings");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_donation_setting = (id, setting) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/donation-settings/${id}`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATION_SETTINGS,
          response.data.description.donation_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const delete_donation_setting = (id) => (dispatch) => {
  if (window.confirm("Are you sure? This cannot be undone")) {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    axios
      .delete(`${API_ENDPOINT}/donation-settings/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_DONATION_SETTINGS,
            response.data.description.donation_settings
          )
        );
      })
      .catch((error) => {
        dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};

export const get_donation_summary = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/donations/summary`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS_SUMMARY,
          response.data.description.summary
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const create_donation = (donation, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/donations`, donation)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/donations");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_donation = (id, donation, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/donations/${id}`, donation)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/donations");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const gets = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/donations?month=${month}&year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS,
          response.data.description.donations
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/donations/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATION,
          response.data.description.donation
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const get_office_donations = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/donations/office/office-donations?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS,
          response.data.description.donations
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_members_donations = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/donations/members/members-donations?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS,
          response.data.description.donations
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_member_donations = (staffId, month = "", year = "") => (
  dispatch
) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/donations/member-donations/${staffId}?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS,
          response.data.description.donations
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_type_donations = (typeId, month = "", year = "") => (
  dispatch
) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/donations/type/type-donations/${typeId}?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_DONATIONS,
          response.data.description.donations
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const upload = (donations, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/donations/upload`, donations)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/donations");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const export_all_donations = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/donations/export/all?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_donations.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_office_donations = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/donations/export/office?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "office_donations.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_members_donations = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/donations/export/members?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "members_donations.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_member_donations = (staffId, month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/donations/export/member/${staffId}?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${staffId}_donations.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};
