import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const gets = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/members`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_MEMBERS,
          response.data.description.members
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(generalPurposeAction(actionTypes.GET_MEMBERS, []));
    });
};

export const get = (id) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/members/${id}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_MEMBER,
          response.data.description.member
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(generalPurposeAction(actionTypes.GET_MEMBER, {}));
    });
};

export const create = (member, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/members`, member)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/members");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update = (id, member) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/members/${id}`, member)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_MEMBERS,
          response.data.description.members
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const upload = (members, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/members/upload`, members)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/members");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const delete_member = (id) => (dispatch) => {
  if (window.confirm("Are you sure? This cannot be undone")) {
    axios
      .delete(`${API_ENDPOINT}/members/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_MEMBERS,
            response.data.description.members
          )
        );
      })
      .catch((error) => {
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};
