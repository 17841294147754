import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_office_incomes,
  export_office_incomes,
} from "../../actions/incomes-action";
import { generalPurposeAction } from "../../actions/common-action";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { TotalEIDColumns } from "../common/TableColumns";
import { months, years, incomeOptions } from "../../constants/mock";
import { Button, SelectListGroup } from "../forms/auth";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import moment from "moment";
import isEmpty from "../../validation/isEmpty";

const OfficeRevenues = () => {
  const ir = useSelector((state) => state.incomes);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [incomes, setIncomes] = useState([]);
  const [values, setValues] = useState({
    month: "",
    year: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [income, setIncome] = useState({});

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.INCOME)
    );
    dispatch(get_office_incomes());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(get_office_incomes(values.month, values.year));
  };

  useEffect(() => {
    const { incomes, loading } = ir;
    setIncomes(incomes);
    setIsLoading(loading);
  }, [ir]);

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    const income = incomes.find((i) => i.id === id);
    setIncome(income);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onExportClick = () => {
    export_office_incomes(values.month, values.year);
  };

  const tableColumns = TotalEIDColumns(
    modalToShow,
    modalTypes.VIEW_INCOME,
    "incomes",
    "edit-income"
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(incomes).length > 0) {
      content = (
        <div className="col-md-12">
          <Table data={incomes} columns={tableColumns} />
        </div>
      );
    } else {
      content = (
        <EmptyBox
          link={"/incomes/create"}
          btnMessage={"Add Income"}
          icon={"fa fa-bitcoin icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No Records Found"}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Total Office Revenue" options={incomeOptions} />
      <div className="m-content">
        <div className="m-portlet">
          <PortletHead title="Office Revenue" />
          <form
            className="m-form m-form--fit m-form--label-align-right"
            onSubmit={onSubmit}
          >
            <div className="form-group m-form__group row">
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.month}
                  name="month"
                  options={months}
                />
              </div>
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.year}
                  name="year"
                  options={years}
                />
              </div>
              <div className="col-lg-2" style={{ marginTop: "20px" }}>
                <Button title="Search" className="btn btn--black text-white" />
              </div>
            </div>
          </form>

          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {!isEmpty(incomes) ? (
                <div className="col mb-3">
                  <button
                    className="btn btn--orange text-white"
                    onClick={() => onExportClick()}
                  >
                    <i className="fa fa-download"></i> Export Office Revenue{" "}
                    <i className="fa fa-file-excel-o"></i>
                  </button>
                </div>
              ) : null}
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.VIEW_INCOME ? (
        <Modal
          show={showModal}
          onClose={() => closeModal()}
          header="View Income"
        >
          <div className="col-md-12">
            <div className="m-content">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <b>Month:</b>
                    </td>
                    <td>{income.month}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Year:</b>
                    </td>
                    <td>{income.year}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Staff ID:</b>
                    </td>
                    <td>{income.member_staff_id}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Income Type:</b>
                    </td>
                    <td>{income.type}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Amount:</b>
                    </td>
                    <td>{income.amount}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Description:</b>
                    </td>
                    <td>{income.description}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created By:</b>
                    </td>
                    <td>{income.added_by}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Modified By:</b>
                    </td>
                    <td>{income.updated_by}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date:</b>
                    </td>
                    <td>
                      {moment(income.date).local().format("ddd, MMM Do YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Time:</b>
                    </td>
                    <td>
                      {moment(income.created_at).local().format("HH.mm a")}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Link
                        to={`/incomes/edit-income/${income.id}`}
                        className="btn btn--orange"
                        style={{ textDecoration: "none", color: "#FFF" }}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default OfficeRevenues;
