import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  get_income_settings,
  create_income,
} from "../../actions/incomes-action";
import { gets } from "../../actions/members-action";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { enableRipple } from "@syncfusion/ej2-base";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { SubHeader, PortletHead } from "../common";
import {
  Button,
  TextFieldGroup,
  TextAreaFieldGroup,
  CustomSelect,
  Checkbox,
  SelectListGroup,
} from "../forms/auth";
import { displayToast } from "../common/Toaster";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import { months, years } from "../../constants/mock";
enableRipple(true);

const CreateIncome = ({ history }) => {
  const today = new Date();
  const dispatch = useDispatch();
  const ir = useSelector((state) => state.incomes);
  const mr = useSelector((state) => state.members);
  const flash = useSelector((state) => state.flash);
  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.loading);
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [typeId, setTypeId] = useState(null);
  const [parentName, setParentName] = useState("");
  const [members, setMembers] = useState([]);
  const [isMember, setIsMember] = useState(false);
  const [amount, setAmount] = useState("");
  const [staffId, setStaffId] = useState("");
  const [month, setMonth] = useState(
    today.toLocaleString("default", { month: "long" })
  );
  const [year, setYear] = useState(today.getFullYear());
  const [description, setDescription] = useState("");
  let incomeTypes = [];
  const cssClass = "custom";
  const node = useRef();
  const { name } = auth.institution;

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.INCOME)
    );
    dispatch(get_income_settings());
    dispatch(gets());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { income_settings } = ir;
    setTypes(income_settings);
    const { members } = mr;
    setMembers(members);
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading, ir, mr]);

  const onChangeAmount = (e) => {
    const { value } = e.target;
    setAmount(value);
  };

  const onChangeDescription = (e) => {
    const { value } = e.target;
    setDescription(value);
  };

  const onChangeMonth = (e) => {
    const { value } = e.target;
    setMonth(value);
  };

  const onChangeYear = (e) => {
    const { value } = e.target;
    setYear(value);
  };

  const onChangeStaffId = (e) => {
    const { value } = e;
    setStaffId(value);
  };

  const onClickAddParentId = (e) => {
    const { id, text } = e.nodeData;
    setTypeId(id);
    setParentName(text);
  };

  const onHandleCheck = (e) => {
    setIsMember(!isMember);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const income = {
      staff_id: !isEmpty(staffId) ? staffId : "office",
      type: typeId,
      amount: amount,
      description: description,
      month: month,
      year: year,
    };
    dispatch(create_income(income, history));
  };

  const options = [
    {
      link: "incomes",
      title: "Incomes",
      icon: "m-nav__link-icon fa fa-bitcoin",
    },
    {
      link: "incomes/office-incomes",
      title: "Office Incomes",
      icon: "m-nav__link-icon fa fa-building",
    },
    {
      link: "incomes/members-incomes",
      title: "Members Incomes",
      icon: "m-nav__link-icon fa fa-users",
    },
    {
      link: "incomes/upload",
      title: "Upload Incomes",
      icon: "m-nav__link-icon fa fa-file-excel-o",
    },
    {
      link: "income-settings",
      title: "Incomes Settings",
      icon: "m-nav__link-icon fa fa-cogs",
    },
  ];

  let memberOptions = [];
  if (!isEmpty(members)) {
    memberOptions = members.map((m) => ({
      value: m.member_staff_id,
      label: `${m.member_name} (${m.member_staff_id})`,
    }));
  }

  if (!isEmpty(types)) {
    types.map((t) =>
      incomeTypes.push(
        t.parent_id === 0
          ? { id: t.id, name: t.type, hasChild: true, expanded: true }
          : {
              id: t.id,
              pid: t.parent_id,
              name: t.type,
              hasChild: true,
              expanded: false,
            }
      )
    );
  }

  const field = {
    dataSource: incomeTypes,
    id: "id",
    text: "name",
    parentID: "pid",
    hasChildren: "hasChild",
  };

  return (
    <React.Fragment>
      <SubHeader options={options} title="Add Income" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Income" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <label>Select Parent Type (Click to select)</label>
                      <TreeViewComponent
                        fields={field}
                        cssClass={cssClass}
                        nodeSelected={onClickAddParentId}
                        ref={node}
                        hide
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <TextFieldGroup
                          label="Selected Type"
                          type="text"
                          onChange={onClickAddParentId}
                          value={parentName}
                          name="parentName"
                          placeholder="Select Income Type on the left side"
                          disabled="disabled"
                        />
                      </div>
                      <div className="row mt-3">
                        <Checkbox
                          type="checkbox"
                          label="Check if income is related to a member"
                          name="isMember"
                          value={isMember}
                          onChange={onHandleCheck}
                        />
                      </div>

                      <div className="row mt-3">
                        {isMember ? (
                          <CustomSelect
                            options={memberOptions}
                            label="Select Member"
                            onChange={onChangeStaffId}
                            name="staffId"
                          />
                        ) : (
                          <TextFieldGroup
                            label="Institute/Society/Organization"
                            type="text"
                            value={name}
                            placeholder=""
                            disabled="disabled"
                          />
                        )}
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <SelectListGroup
                            options={months}
                            label="Select Month"
                            onChange={onChangeMonth}
                            name="month"
                            value={month}
                          />
                        </div>
                        <div className="col-md-6">
                          <SelectListGroup
                            options={years}
                            label="Select Year"
                            onChange={onChangeYear}
                            name="year"
                            value={year}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <TextFieldGroup
                          label="Amount"
                          type="text"
                          onChange={onChangeAmount}
                          value={amount}
                          name="amount"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="row mt-3">
                        <TextAreaFieldGroup
                          label="Describe income briefly"
                          onChange={onChangeDescription}
                          value={description}
                          name="description"
                          placeholder="description"
                          rows="5"
                        />
                      </div>
                      <div className="row mt-3">
                        <Button
                          title="Save"
                          className={"btn btn--black text-white"}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateIncome);
