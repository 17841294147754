import actionTypes from "../constants/types";

const initialState = {
  institution: {},
  utitlity: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INSTITUTION:
      return {
        ...state,
        institution: action.payload,
      };
    case actionTypes.GET_UTILITY:
      return {
        ...state,
        utility: action.payload,
      };
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
