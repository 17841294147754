import React from "react";
import { Link } from "react-router-dom";

const EmptyBox = ({ info, link, icon, btnMessage, btnIcon }) => {
  return (
    <div className="col-md-12">
      <div className="no-data align-center">
        <h4>{info}</h4>
        <i className={icon} />
        <p>
          <Link
            to={link}
            className="btn m-btn--pill btn--black btn-lg text-white"
          >
            <i className={btnIcon} /> {btnMessage}
          </Link>
        </p>
      </div>
    </div>
  );
};

export { EmptyBox };
