import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  set_utility,
  update_utility,
  get_utility,
} from "../../actions/institution-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import isEmpty from "../../validation/isEmpty";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";

const SetContribution = ({ history }) => {
  const dispatch = useDispatch();
  const institute = useSelector((state) => state.institution);
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    amount: "",
    payment_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(get_utility());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { utility } = institute;
    if (!isEmpty(utility)) {
      setValues({ amount: utility.amount, payment_type: "" });
    }
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [institute, flash, loading]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSave = (e) => {
    e.preventDefault();
    const { payment_type } = auth.institution;
    const amount = {
      amount: values.amount,
      payment_type: payment_type,
    };
    dispatch(set_utility(amount, history));
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const { payment_type } = auth.institution;
    const { id } = institute.utility;
    const amount = {
      amount: values.amount,
      payment_type: payment_type,
    };
    dispatch(update_utility(id, amount, history));
  };

  const options = [
    {
      link: "institution/set-contribution",
      title: "Set Contribution",
      icon: "m-nav__link-icon fa fa-key",
    },
    {
      link: "expense-settings",
      title: "Expense Settings",
      icon: "m-nav__link-icon fa fa-money",
    },
    {
      link: "income-settings",
      title: "Income Settings",
      icon: "m-nav__link-icon fa fa-bitcoin",
    },
    {
      link: "donation-settings",
      title: "Donation Settings",
      icon: "m-nav__link-icon fa fa-dollar",
    },
    {
      link: "loan-settings",
      title: "Loan Settings",
      icon: "m-nav__link-icon fa fa-euro",
    },
  ];

  return (
    <React.Fragment>
      <SubHeader options={options} title="Set Uniform Amount" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Set the amount for uniform contribution" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                {isEmpty(institute.utility) ? (
                  <form
                    className="m-form m-form--fit m-form--label-align-right"
                    onSubmit={onSave}
                  >
                    <div className="form-group m-form__group row">
                      <div className="col-lg-6">
                        <TextFieldGroup
                          label="Amount"
                          onChange={onChange}
                          value={values.amount}
                          name="amount"
                          type="text"
                          placeholder="Enter amount for uniform payment"
                        />
                      </div>
                    </div>
                    <div className="m-form__actions">
                      <Button
                        title="Save Amount"
                        className="btn btn--black text-white"
                        loading={isLoading}
                      />
                    </div>
                  </form>
                ) : (
                  <form
                    className="m-form m-form--fit m-form--label-align-right"
                    onSubmit={onUpdate}
                  >
                    <div className="form-group m-form__group row">
                      <div className="col-lg-6">
                        <TextFieldGroup
                          label="Amount"
                          onChange={onChange}
                          value={values.amount}
                          type="text"
                          name="amount"
                          placeholder="Enter amount for uniform payment"
                        />
                      </div>
                    </div>
                    <div className="m-form__actions">
                      <Button
                        title={"Update Amount"}
                        className={"btn btn--black text-white"}
                        loading={isLoading}
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SetContribution);
