const actionTypes = {
  DESTROY_ALL_STATES: "DESTROY_ALL_STATES",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_USER_INSTITUTION: "SET_USER_INSTITUTION",
  SET_ACTIVE_MENU: "SET_ACTIVE_MENU",
  CLEAR_CURRENT_PROFILE: "CLEAR_CURRENT_PROFILE",
  ADD_FLASH_MESSAGE: "ADD_FLASH_MESSAGE",
  CLEAR_FLASH_MESSAGE: "CLEAR_FLASH_MESSAGE",
  SET_LOGO: "SET_LOGO",
  SUBMIT_LOADING: "SUBMIT_LOADING",
  GET_INSTITUTION: "GET_INSTITUTION",
  GET_UTILITY: "GET_UTILITY",
  GENERAL_LOADING: "GENERAL_LOADING",
  GET_MEMBERS: "GET_MEMBERS",
  GET_MEMBER: "GET_MEMBER",
  GET_CONTRIBUTIONS: "GET_CONTRIBUTIONS",
  GET_CONTRIBUTION: "GET_CONTRIBUTION",
  GET_CONTRIBUTIONS_SUMMARY: "GET_CONTRIBUTIONS_SUMMARY",
  GET_LOANS: "GET_LOANS",
  GET_LOAN: "GET_LOAN",
  GET_LOANS_SUMMARY: "GET_LOANS_SUMMARY",
  GET_USERS: "GET_USERS",
  GET_USER: "GET_USER",
  GET_TOTAL_CONTRIBUTIONS: "GET_TOTAL_CONTRIBUTIONS",
  GET_TOTAL_LOANS: "GET_TOTAL_LOANS",
  GET_TOTAL_DONATIONS: "GET_TOTAL_DONATIONS",
  GET_TOTAL_MEMBERS: "GET_TOTAL_MEMBERS",
  GET_TOTAL_EXPENSES: "GET_TOTAL_EXPENSES",
  GET_TOTAL_INCOMES: "GET_TOTAL_INCOMES",
  GET_LOAN_SETTINGS: "GET_LOAN_SETTINGS",
  GET_LOAN_SETTING: "GET_LOAN_SETTING",
  GET_LOAN_PAYMENTS: "GET_LOAN_PAYMENTS",
  LOADING_CLIENTS: "LOADING_CLIENTS",
  GET_CLIENTS: "GET_CLIENTS",
  SHOW_MODAL: "SHOW_MODAL",
  GET_EXPENSE_SETTINGS: "GET_EXPENSE_SETTINGS",
  GET_EXPENSE_SETTING: "GET_EXPENSE_SETTING",
  GET_EXPENSES_SUMMARY: "GET_EXPENSES_SUMMARY",
  GET_EXPENSES: "GET_EXPENSES",
  GET_EXPENSE: "GET_EXPENSE",
  GET_INCOME_SETTINGS: "GET_INCOME_SETTINGS",
  GET_INCOME_SETTING: "GET_INCOME_SETTING",
  GET_INCOMES_SUMMARY: "GET_INCOMES_SUMMARY",
  GET_INCOMES: "GET_INCOMES",
  GET_INCOME: "GET_INCOME",
  GET_DONATION_SETTINGS: "GET_DONATION_SETTINGS",
  GET_DONATION_SETTING: "GET_DONATION_SETTING",
  GET_DONATIONS_SUMMARY: "GET_DONATIONS_SUMMARY",
  GET_DONATIONS: "GET_DONATIONS",
  GET_DONATION: "GET_DONATION",
  GET_TOTALS: "GET_TOTALS",
  GET_YEARLY_SUMMARY: "GET_YEARLY_SUMMARY",
};

export default actionTypes;
