import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const get_totals = (year) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/admin/welfare/get-totals?year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_TOTALS,
          response.data.description.totals
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_yearly_summary = (year) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/admin/welfare/get-yearly-summary?year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_YEARLY_SUMMARY,
          response.data.description.yearly_summary
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};
