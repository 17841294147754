import React from "react";

const TextFieldGroup = ({
  label,
  name,
  placeholder,
  info,
  value,
  type,
  onChange,
  autoComplete,
  disabled,
  min,
}) => {
  return (
    <React.Fragment>
      <label>{label}</label>
      <input
        type={type}
        className={"form-control m-input"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        disabled={disabled}
        min={min}
      />
      {info && <span className="m-form__help">{info}</span>}
    </React.Fragment>
  );
};

export { TextFieldGroup };
