import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import SecureLS from "secure-ls";
import { Title } from "../common";
import { generalPurposeAction } from "../../actions/common-action";
import { TextFieldGroup, Button, RedirectLinks } from "../forms/guest";
import isEmpty from "../../validation/isEmpty";
import { activate } from "../../actions/auth-action";
import actionTypes from "../../constants/types";
import { displayToast } from "../common/Toaster";
import "./Auth.css";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });

const AccountVerification = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      history.push("/dashboard");
    }
    const data = ls.get("data");
    const { email } = data.description;
    if (isEmpty(email)) {
      history.push("/auth/login");
    }
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setCode(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const data = ls.get("data");
    const { email } = data.description;

    const user = {
      email: email,
      code: code,
    };
    dispatch(activate(user, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  return (
    <div className="background-cover">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2">
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
          <div className="m-login__container">
            <div className="login-section">
              <div className="m-login__signin">
                <Title title="Activate Account" />
                <form className="m-login__form m-form" onSubmit={onSubmit}>
                  <TextFieldGroup
                    placeholder={"Code"}
                    value={code}
                    name={"code"}
                    onChange={onChange}
                    type={"type"}
                  />

                  <div className="m-login__form-action">
                    <Button
                      title={"Activate Account"}
                      className={"custom-button btn--black"}
                      loading={isLoading}
                    />
                    <RedirectLinks
                      title={"Already Registered. Login"}
                      link={"/login"}
                      className={"m-link m-link--dark m-login__account-link"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AccountVerification);
