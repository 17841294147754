import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_contribution_summary,
  export_summary,
  get_members_without_contribution,
} from "../../actions/contributions-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import {
  ContributionSummaryColumns,
  MembersWithoutContributionColumns,
} from "../common/TableColumns";

const Contributions = () => {
  const cr = useSelector((state) => state.contributions);
  const mr = useSelector((state) => state.members);
  const dispatch = useDispatch();
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.CONTRIBUTIONS)
    );
    dispatch(get_contribution_summary());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { summary, loading } = cr;
    if (!isEmpty(summary)) {
      setSummary(summary);
    }
    setIsLoading(loading);
    const { members } = mr;
    if (!isEmpty(members)) {
      setMembers(members);
    }
  }, [cr, mr]);

  const modalToShow = (month, year, type) => {
    dispatch(get_members_without_contribution(month, year));
    setShowModal(true);
    setModalType(type);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const exportSummary = (month, year) => {
    export_summary(month, year);
  };

  const options = [
    {
      link: "contributions/create",
      title: "Add Contribution",
      icon: "m-nav__link-icon fa fa-plus",
    },
    {
      link: "contributions/upload",
      title: "Upload Contributions",
      icon: "m-nav__link-icon fa fa-file-excel-o",
    },
  ];

  const tableColumns = ContributionSummaryColumns(
    modalToShow,
    modalTypes.NO_CONTRIBUTION,
    exportSummary
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(summary).length === 0) {
      content = (
        <EmptyBox
          link={"/contributions/create"}
          btnMessage={"Add Contribution"}
          icon={"fa fa-bank icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No contribution has been made"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={summary} columns={tableColumns} />
        </div>
      );
    }
  }

  const membersColumns = MembersWithoutContributionColumns();

  return (
    <React.Fragment>
      <SubHeader title={"Contributions"} options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Contributions Summary" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.NO_CONTRIBUTION ? (
        <Modal
          onClose={() => closeModal()}
          header="Members without Contributions"
        >
          <div className="col-md-12">
            <Table data={members} columns={membersColumns} />
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default Contributions;
