import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export const MembersTableColumns = (onDeleteClick, modalToShow, modalType) => {
  return useMemo(
    () => [
      {
        Header: "Member ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Member Name",
        accessor: "member_name",
      },
      {
        Header: "Member Phone",
        accessor: "member_phone",
      },
      {
        Header: "Member Email",
        accessor: "member_email",
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.created_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-info"
                  title="Edit"
                  onClick={() =>
                    modalToShow(props.cell.row.original.id, modalType)
                  }
                >
                  <i className="flaticon-edit"></i>
                </button>
                <Link
                  to={`members/view/${props.cell.row.original.id}/${props.cell.row.original.member_staff_id}`}
                  className="btn btn--black text-white"
                  style={{ textDecoration: "none" }}
                >
                  <i className="fa fa-eye"></i>
                </Link>
                <button
                  type="button"
                  className="btn btn-danger"
                  title="Delete"
                  onClick={() => onDeleteClick(props.cell.row.original.id)}
                >
                  <i className="fa fa-trash-o"></i>
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [onDeleteClick, modalToShow, modalType]
  );
};

export const ContributionSummaryColumns = (
  modalToShow,
  modalType,
  exportSummary
) => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Total",
        accessor: "total",
        filterable: false,
        Cell: (props) =>
          props.cell.row.original.total > 0 ? (
            <Link
              to={`contributions/total-contributions/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#2962FF", textDecoration: "underline" }}
            >
              {props.cell.row.original.total}
            </Link>
          ) : (
            <span>{props.cell.row.original.total}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row.values.total) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total}</>;
        },
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        filterable: false,
        Cell: (props) =>
          parseFloat(props.cell.row.original.amount) > 0.0 ? (
            <Link
              to={`contributions/total-contributions/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#ff7738", textDecoration: "underline" }}
            >
              {props.cell.row.original.amount}
            </Link>
          ) : (
            <span>{props.cell.row.original.amount}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "No Contributions",
        accessor: "not_contributed",
        filterable: false,
        Cell: (props) =>
          props.cell.row.original.not_contributed > 0 ? (
            <button
              className="btn btn-default"
              onClick={() =>
                modalToShow(
                  props.cell.row.original.month,
                  props.cell.row.original.year,
                  modalType
                )
              }
            >
              {props.cell.row.original.not_contributed}
            </button>
          ) : (
            <span>{props.cell.row.original.not_contributed}</span>
          ),
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              {props.cell.row.original.not_contributed === 0 ? (
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className="btn btn--orange text-white"
                    title="Export"
                    onClick={() =>
                      exportSummary(
                        props.cell.row.original.month,
                        props.cell.row.original.year
                      )
                    }
                  >
                    <i className="fa fa-file-excel-o"></i>
                  </button>
                </div>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, exportSummary, modalType]
  );
};

export const MembersWithoutContributionColumns = () => {
  return useMemo(
    () => [
      {
        Header: "Staff ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Member Name",
        accessor: "member_name",
      },
      {
        Header: "Member Phone",
        accessor: "member_phone",
      },
      {
        Header: "Member Email",
        accessor: "member_email",
      },
    ],
    []
  );
};

export const TotalContributionsColumns = () => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Staff ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Name",
        accessor: "member_name",
      },
      {
        Header: "Phone",
        accessor: "member_phone",
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.date).local().format("ddd, MMM Do YYYY");
        },
      },
    ],
    []
  );
};

export const LoansSummaryColumns = () => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: (props) =>
          props.cell.row.original.total > 0 ? (
            <Link
              to={`loans/total-loans/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#2962FF", textDecoration: "underline" }}
            >
              {props.cell.row.original.total}
            </Link>
          ) : (
            <span>{props.cell.row.original.total}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row.values.total) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total}</>;
        },
      },
      {
        Header: "Amount Loaned (GHS)",
        accessor: "amount_loaned",
        Cell: (props) =>
          parseFloat(props.cell.row.original.amount_loaned) > 0.0 ? (
            <Link
              to={`loans/total-loans/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#ff7738", textDecoration: "underline" }}
            >
              {props.cell.row.original.amount_loaned}
            </Link>
          ) : (
            <span>{props.cell.row.original.amount_loaned}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount_loaned) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Amount Returned (GHS)",
        accessor: "amount_paid",
        filterable: false,
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount_paid) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
    ],
    []
  );
};

export const LoanSettingsColumns = (modalToShow, modalType, onDeleteClick) => {
  return useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Minimum Months",
        accessor: "min_month",
      },
      {
        Header: "Maximum Months",
        accessor: "max_month",
      },
      {
        Header: "Date Created",
        accessor: (d) => {
          return moment(d.created_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  title="Edit"
                  onClick={() =>
                    modalToShow(props.cell.row.original.id, modalType)
                  }
                >
                  <i className="flaticon-edit"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  title="Delete"
                  onClick={() => onDeleteClick(props.cell.row.original.id)}
                >
                  <i className="fa fa-trash-o"></i>
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [onDeleteClick, modalToShow, modalType]
  );
};

export const GrantLoanColumns = (modalToShow, modalType) => {
  return useMemo(
    () => [
      {
        Header: "Member ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Member Name",
        accessor: "member_name",
      },
      {
        Header: "Member Phone",
        accessor: "member_phone",
      },
      {
        Header: "Member Email",
        accessor: "member_email",
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    modalToShow(
                      props.cell.row.original.member_staff_id,
                      modalType
                    )
                  }
                >
                  <i className="fa fa-dollar"></i> Grant Loan
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, modalType]
  );
};

export const TotalLoanColumns = (modalToShow, modalTypes) => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Staff ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Name",
        accessor: "member_name",
      },
      {
        Header: "Loan Type",
        accessor: "loan_type",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Amount Loaned",
        accessor: "amount_loaned",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount_loaned) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Interest",
        accessor: "interest",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.interest) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        Cell: (props) =>
          props.cell.row.original.amount_paid > 0 ? (
            <span
              onClick={() =>
                modalToShow(
                  props.cell.row.original.id,
                  modalTypes.VIEW_LOAN_PAYMENTS
                )
              }
              style={{
                color: "#81C784",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {props.cell.row.original.amount_paid}
            </span>
          ) : (
            <span style={{ color: "#81C784" }}>
              {props.cell.row.original.amount_paid}
            </span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount_paid) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Amount Owing",
        accessor: "amount_owing",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount_owing) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Return Date",
        accessor: (d) => {
          return moment(d.return_date).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Granted by",
        accessor: "added_by",
      },
      {
        Header: "Status",
        accessor: (d) => {
          let status;
          if (d.status === "Paid") {
            status = <span style={{ color: "#00C853" }}>{d.status}</span>;
          } else if (d.status === "Owing") {
            status = <span style={{ color: "#F44336" }}>{d.status}</span>;
          }
          return status;
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    modalToShow(
                      props.cell.row.original.id,
                      modalTypes.MAKE_LOAN_PAYMENT
                    )
                  }
                >
                  <i className="fa fa-dollar"></i> Pay Loan
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, modalTypes]
  );
};

export const LoanPaymentsMembersColumn = () => {
  return useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Date Paid",
        accessor: (d) => {
          return moment(d.return_date).local().format("ddd, MMM Do YYYY");
        },
      },
    ],
    []
  );
};

export const SettingsColumns = (onDeleteClick) => {
  return useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Parent",
        accessor: "parent",
      },
      {
        Header: "Date Created",
        accessor: (d) => {
          return moment(d.created_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Date Modified",
        accessor: (d) => {
          return moment(d.updated_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-danger"
                  title="Delete"
                  onClick={() => onDeleteClick(props.cell.row.original.id)}
                >
                  <i className="fa fa-trash-o"></i>
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [onDeleteClick]
  );
};

export const SummaryColumns = (onExportClick, mainLink, subLink) => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Total",
        accessor: "total",
        filterable: false,
        Cell: (props) =>
          props.cell.row.original.total > 0 ? (
            <Link
              to={`${mainLink}/${subLink}/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#2962FF", textDecoration: "underline" }}
            >
              {props.cell.row.original.total}
            </Link>
          ) : (
            <span>{props.cell.row.original.total}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row.values.total) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total}</>;
        },
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        filterable: false,
        Cell: (props) =>
          parseFloat(props.cell.row.original.amount) > 0.0 ? (
            <Link
              to={`${mainLink}/${subLink}/${props.cell.row.original.month}/${props.cell.row.original.year}`}
              style={{ color: "#ff7738", textDecoration: "underline" }}
            >
              {props.cell.row.original.amount}
            </Link>
          ) : (
            <span>{props.cell.row.original.amount}</span>
          ),
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <Link
                  to={`${mainLink}/${subLink}/${props.cell.row.original.month}/${props.cell.row.original.year}`}
                  className="btn btn--orange text-white"
                  style={{ textDecoration: "none" }}
                >
                  View Details
                </Link>
                <button
                  className="btn btn--black text-white"
                  onClick={() =>
                    onExportClick(
                      props.cell.row.original.month,
                      props.cell.row.original.year
                    )
                  }
                >
                  <i className="fa fa-file-excel-o"></i> Export
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [onExportClick, mainLink, subLink]
  );
};

export const TotalEIDColumns = (modalToShow, modalType, mainLink, subLink) => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Expense Type",
        accessor: "type",
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Added By",
        accessor: "added_by",
      },
      {
        Header: "Modified By",
        accessor: "updated_by",
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.date).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    modalToShow(props.cell.row.original.id, modalType)
                  }
                >
                  <i className="fa fa-eye"></i>
                </button>
                <Link
                  to={`/${mainLink}/${subLink}/${props.cell.row.original.id}`}
                  className="btn btn--orange"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <i className="fa fa-edit"></i>
                </Link>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, modalType, mainLink, subLink]
  );
};

export const MembersEIDColumns = (
  modalToShow,
  modalType,
  mainLink,
  subLink
) => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Staff Id",
        accessor: "member_staff_id",
      },
      {
        Header: "Name",
        accessor: "member_name",
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Added By",
        accessor: "added_by",
      },
      {
        Header: "Modified By",
        accessor: "updated_by",
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.date).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    modalToShow(props.cell.row.original.id, modalType)
                  }
                >
                  <i className="fa fa-eye"></i>
                </button>
                <Link
                  to={`/${mainLink}/${subLink}/${props.cell.row.original.id}`}
                  className="btn btn--orange"
                  style={{ textDecoration: "none", color: "#FFF" }}
                >
                  <i className="fa fa-edit"></i>
                </Link>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, modalType, mainLink, subLink]
  );
};

export const MemberCEDIColumns = () => {
  return useMemo(
    () => [
      {
        Header: "Month",
        accessor: "month",
      },
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Staff ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Amount in GHS",
        accessor: "amount",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.amount) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.date).local().format("ddd, MMM Do YYYY");
        },
      },
    ],
    []
  );
};

export const MemberLoans = () => {
  return useMemo(
    () => [
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Staff ID",
        accessor: "member_staff_id",
      },
      {
        Header: "Amount Loaned",
        accessor: "amount_loaned",
      },
      {
        Header: "Interest",
        accessor: "interest",
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
      },
      {
        Header: "Amount Owing",
        accessor: "amount_owing",
      },
      {
        Header: "Return Date",
        accessor: (d) => {
          return moment(d.return_date).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );
};

export const UsersColumns = (
  modalToShow,
  modalType,
  onGrantAccessClick,
  onRevokeAccessClick
) => {
  return useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Date Added",
        accessor: (d) => {
          return moment(d.created_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,

        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    modalToShow(props.cell.row.original.id, modalType)
                  }
                >
                  <i className="flaticon-edit"></i>
                </button>
                {props.cell.row.original.is_revoke === 0 ? (
                  <button
                    className="btn btn-danger text-white"
                    onClick={() =>
                      onRevokeAccessClick(props.cell.row.original.id)
                    }
                  >
                    <i className="fa fa-lock" /> Revoke Access
                  </button>
                ) : (
                  <button
                    className="btn btn-success text-white"
                    onClick={() =>
                      onGrantAccessClick(props.cell.row.original.id)
                    }
                  >
                    <i className="fa fa-key" />
                    Grant Access
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [modalToShow, modalType, onGrantAccessClick, onRevokeAccessClick]
  );
};

export const ClientsColumns = (onImpersonateClient) => {
  return useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "inst_name",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Members",
        accessor: "members",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseInt(row.values.members) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total}</>;
        },
      },
      {
        Header: "Contributions",
        accessor: "contributions",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.contributions) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Loans",
        accessor: "loans",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.loans) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Donations",
        accessor: "donations",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.donations) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Expenses",
        accessor: "expenses",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.expenses) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Income",
        accessor: "incomes",
        Footer: (info) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.values.incomes) + sum,
                0
              ),
            [info.rows]
          );

          return <>{total.toFixed(2)}</>;
        },
      },
      {
        Header: "Date",
        accessor: (d) => {
          return moment(d.created_at).local().format("ddd, MMM Do YYYY");
        },
      },
      {
        Header: "Actions",
        filterable: false,
        sortable: false,

        Cell: (props) => {
          return (
            <React.Fragment>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn--black text-white"
                  onClick={() =>
                    onImpersonateClient(props.cell.row.original.user_id)
                  }
                >
                  <i className="fa fa-location-arrow"></i>
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    [onImpersonateClient]
  );
};
