import React from "react";
import store from "./store";
import { setAuthToken } from "./utils/set-auth-token";
import { generalPurposeAction } from "./actions/common-action";
import { logout } from "./actions/auth-action";
import jwt_decode from "jwt-decode";
import SecureLS from "secure-ls";
import isEmpty from "./validation/isEmpty";
import Navigation from "./Navigation";
import "./App.css";
import actionTypes from "./constants/types";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });
const token = ls.get("jwtToken");
const user = ls.get("user");
const institution = ls.get("institution");
const logo = ls.get("logo");

if (!isEmpty(token)) {
  setAuthToken(token);
  store.dispatch(generalPurposeAction(actionTypes.SET_CURRENT_USER, user));
  store.dispatch(
    generalPurposeAction(actionTypes.SET_USER_INSTITUTION, institution)
  );
  store.dispatch(generalPurposeAction(actionTypes.SET_LOGO, logo));

  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    store.dispatch(logout());
    // Redirect to login
    window.location.href = "/auth/login";
  }
}

const App = () => {
  return (
    <React.Fragment>
      <Navigation store={store} />
    </React.Fragment>
  );
};

export default App;
