import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const get_loan_summary = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/loans/summary`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOANS_SUMMARY,
          response.data.description.summary
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(generalPurposeAction(actionTypes.GET_LOANS_SUMMARY, []));
    });
};

export const gets = (month, year) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/loans?month=${month}&year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOANS,
          response.data.description.loans
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(generalPurposeAction(actionTypes.GET_LOANS, []));
    });
};

export const get = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/loans/loan/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOAN,
          response.data.description.loan
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_LOAN, {}));
    });
};

export const create = (loan, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/loans`, loan)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/loans");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update = (id, loan) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/loans/${id}`, loan)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOANS,
          response.data.description.loans
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const get_member_loans = (member_id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/loans/member/loan/${member_id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOANS,
          response.data.description.loans
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_LOANS, []));
    });
};

export const get_loan_settings = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/loan-settings`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOAN_SETTINGS,
          response.data.description.loan_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(generalPurposeAction(actionTypes.GET_LOAN_SETTINGS, []));
    });
};

export const get_loan_setting = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/loan-settings/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOAN_SETTING,
          response.data.description.loan_setting
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_LOAN_SETTING, {}));
    });
};

export const create_loan_setting = (setting, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/loan-settings`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/loan-settings");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_loan_setting = (id, setting) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/loan-settings/${id}`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOAN_SETTINGS,
          response.data.description.loan_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const delete_loan_setting = (id) => (dispatch) => {
  if (window.confirm("Are you sure? This cannot be undone")) {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    axios
      .delete(`${API_ENDPOINT}/loan-settings/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_LOAN_SETTINGS,
            response.data.description.loan_settings
          )
        );
      })
      .catch((error) => {
        dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};

export const get_payments = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/loans/payments/loan/payment/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_LOAN_PAYMENTS,
          response.data.description.payments
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_LOAN_PAYMENTS, []));
    });
};
