import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generalPurposeAction } from "../../actions/common-action";
import { update_profile } from "../../actions/auth-action";
import { SubHeader, PortletHead } from "../common";
import isEmpty from "../../validation/isEmpty";
import { Button, TextFieldGroup } from "../forms/auth";
import actionTypes from "../../constants/types";
import { displayToast } from "../common/Toaster";

const Profile = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    const { user } = auth;
    user.name = !isEmpty(user.name) ? user.name : "";
    user.email = !isEmpty(user.email) ? user.email : "";
    user.phone = !isEmpty(user.phone) ? user.phone : "";
    setValues({ name: user.name, email: user.email, phone: user.phone });
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const profileData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
    };
    dispatch(update_profile(profileData));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  const options = [
    {
      link: "change-password",
      title: "Change Password",
      icon: "m-nav__link-icon fa fa-key",
    },
  ];

  return (
    <div>
      <SubHeader options={options} title="Edit Profile" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Profile Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Name"
                        onChange={onChange}
                        value={values.name}
                        name="name"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Email"
                        onChange={onChange}
                        value={values.email}
                        name="email"
                        type="text"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Phone Number"
                        onChange={onChange}
                        value={values.phone}
                        name="phone"
                        type="text"
                        placeholder="Phone number"
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title={"Save Profile"}
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
