import React from "react";

const Checkbox = ({ name, value, type, onChange, label, checked }) => {
  return (
    <React.Fragment>
      <label className="m-checkbox m-checkbox--focus">
        <input
          type={type}
          className="form-check-input"
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
        />
        {label}
        <span style={{ top: "0" }} />
      </label>
      <span />
    </React.Fragment>
  );
};

export { Checkbox };
