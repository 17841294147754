import actionTypes from "../constants/types";

const initialState = {
  summary: [],
  contributions: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CONTRIBUTIONS_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case actionTypes.GET_CONTRIBUTIONS:
      return {
        ...state,
        contributions: action.payload,
      };
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
