import React from "react";
import { Link } from "react-router-dom";

const SubHeader = ({ title, options }) => {
  let addLinks;

  if (options === null) {
    addLinks = (
      <div className="m-subheader ">
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            <h3 className="m-subheader__title">{title}</h3>
          </div>
        </div>
      </div>
    );
  } else {
    const links = options.map((option) => (
      <li className="m-nav__item" key={option.title}>
        <Link to={`/${option.link}`} className="m-nav__link">
          <i className={option.icon} />
          <span className="m-nav__link-text">{option.title}</span>
        </Link>
      </li>
    ));

    addLinks = (
      <div>
        <div className="m-subheader ">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h3 className="m-subheader__title">{title}</h3>
            </div>
            <div>
              <div>
                <div
                  className="m-dropdown m-dropdown--inline m-dropdown--arrow m-dropdown--align-right m-dropdown--align-push"
                  m-dropdown-toggle="hover"
                  aria-expanded="true"
                >
                  <Link
                    to="#"
                    className="m-portlet__nav-link btn btn-lg btn-secondary  m-btn m-btn--outline-2x m-btn--air m-btn--icon m-btn--icon-only m-btn--pill  m-dropdown__toggle"
                  >
                    <i className="la la-plus m--hide" />
                    <i className="la la-ellipsis-h" />
                  </Link>
                  <div className="m-dropdown__wrapper">
                    <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                    <div className="m-dropdown__inner">
                      <div className="m-dropdown__body">
                        <div className="m-dropdown__content">
                          <ul className="m-nav">{links}</ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>{addLinks}</div>;
};

export { SubHeader };
