import actionTypes from "../constants/types";

const initialState = {
  summary: [],
  expenses: [],
  expense: {},
  expense_settings: [],
  expense_setting: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_EXPENSE_SETTINGS:
      return {
        ...state,
        expense_settings: action.payload,
      };
    case actionTypes.GET_EXPENSE_SETTING:
      return {
        ...state,
        expense_setting: action.payload,
      };
    case actionTypes.GET_EXPENSES_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case actionTypes.GET_EXPENSES:
      return {
        ...state,
        expenses: action.payload,
      };
    case actionTypes.GET_EXPENSE:
      return {
        ...state,
        expense: action.payload,
      };
    default:
      return state;
  }
}
