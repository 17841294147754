import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

export const get_income_settings = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/income-settings`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOME_SETTINGS,
          response.data.description.income_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_income_setting = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/income-settings/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOME_SETTING,
          response.data.description.income_setting
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const get_income_settings_with_parent = (parentId) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/income-settings/parent?parent_id=${parentId}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOME_SETTINGS,
          response.data.description.income_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const create_income_setting = (setting, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/income-settings`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/income-settings");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_income_setting = (id, setting) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/income-settings/${id}`, setting)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOME_SETTINGS,
          response.data.description.income_settings
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const delete_income_setting = (id) => (dispatch) => {
  if (window.confirm("Are you sure? This cannot be undone")) {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    axios
      .delete(`${API_ENDPOINT}/income-settings/${id}`)
      .then((response) => {
        dispatch(
          generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
        );
        dispatch(
          generalPurposeAction(
            actionTypes.GET_INCOME_SETTINGS,
            response.data.description.income_settings
          )
        );
      })
      .catch((error) => {
        dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
        dispatch(
          generalPurposeAction(
            actionTypes.ADD_FLASH_MESSAGE,
            error.response.data
          )
        );
      });
  }
};

export const get_income_summary = () => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/incomes/summary`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES_SUMMARY,
          response.data.description.summary
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const create_income = (income, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/incomes`, income)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/incomes");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_income = (id, income, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/incomes/${id}`, income)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/incomes");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const gets = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/incomes?month=${month}&year=${year}`)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES,
          response.data.description.incomes
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get = (id) => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/incomes/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOME,
          response.data.description.income
        )
      );
    })
    .catch((error) => {
      console.error(error);
    });
};

export const get_office_incomes = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/incomes/office/office-incomes?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES,
          response.data.description.incomes
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_members_incomes = (month = "", year = "") => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/incomes/members/members-incomes?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES,
          response.data.description.incomes
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_member_incomes = (staffId, month = "", year = "") => (
  dispatch
) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/incomes/member-incomes/${staffId}?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES,
          response.data.description.incomes
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const get_type_incomes = (typeId, month = "", year = "") => (
  dispatch
) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(
      `${API_ENDPOINT}/incomes/type/type-incomes/${typeId}?month=${month}&year=${year}`
    )
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INCOMES,
          response.data.description.incomes
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      console.error(error);
    });
};

export const upload = (incomes, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/incomes/upload`, incomes)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      history.push("/incomes");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const export_all_incomes = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/incomes/export/all?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_incomes.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_office_incomes = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/incomes/export/office?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "office_incomes.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_members_incomes = (month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/incomes/export/members?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "members_incomes.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const export_member_incomes = (staffId, month = "", year = "") => {
  axios({
    url: `${API_ENDPOINT}/incomes/export/member/${staffId}?month=${month}&year=${year}`,
    method: "GET",
    responseType: "blob", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${staffId}_incomes.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error(error);
    });
};
