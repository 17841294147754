import axios from "axios";
import { API_ENDPOINT } from "./api-endpoint";
import SecureLS from "secure-ls";
import actionTypes from "../constants/types";
import { generalPurposeAction } from "./common-action";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });

export const get = (id) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, true));
  axios
    .get(`${API_ENDPOINT}/institution/${id}`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INSTITUTION,
          response.data.description.institution
        )
      );
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_INSTITUTION, {}));
      dispatch(generalPurposeAction(actionTypes.GENERAL_LOADING, false));
      // console.error(error);
    });
};

export const create = (institution, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/institution`, institution)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      const { institution, auth } = response.data.description;

      ls.set("institution", institution);
      ls.set("inst_name", institution.name);
      ls.set("user", auth);

      dispatch(generalPurposeAction(actionTypes.SET_CURRENT_USER, auth));
      dispatch(
        generalPurposeAction(actionTypes.SET_USER_INSTITUTION, institution)
      );
      history.push("/institution");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update = (id, institution) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/institution/${id}`, institution)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      const { institution } = response.data.description;
      ls.set("institution", institution);
      ls.set("inst_name", institution.name);

      dispatch(
        generalPurposeAction(actionTypes.SET_USER_INSTITUTION, institution)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INSTITUTION,
          response.data.description.institution
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const set_logo = (logo) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/institution/logo`, logo)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );

      const { institution } = response.data.description;
      ls.set("logo", institution.logo);

      dispatch(generalPurposeAction(actionTypes.SET_LOGO, institution.logo));
      dispatch(
        generalPurposeAction(
          actionTypes.GET_INSTITUTION,
          response.data.description.institution
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const get_utility = () => (dispatch) => {
  axios
    .get(`${API_ENDPOINT}/utility/uniform-amount`)
    .then((response) => {
      dispatch(
        generalPurposeAction(
          actionTypes.GET_UTILITY,
          response.data.description.utility
        )
      );
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.GET_UTILITY, {}));
      console.log(error);
    });
};

export const set_utility = (amount, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .post(`${API_ENDPOINT}/utility/set-uniform-payment`, amount)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );
      dispatch(
        generalPurposeAction(
          actionTypes.GET_UTILITY,
          response.data.description.utility
        )
      );
      history.push("/institution");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};

export const update_utility = (id, amount, history) => (dispatch) => {
  dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
  dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, true));
  axios
    .put(`${API_ENDPOINT}/utility/update-uniform-payment/${id}`, amount)
    .then((response) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, response.data)
      );

      dispatch(
        generalPurposeAction(
          actionTypes.GET_UTILITY,
          response.data.description.utility
        )
      );
      history.push("/institution");
    })
    .catch((error) => {
      dispatch(generalPurposeAction(actionTypes.SUBMIT_LOADING, false));
      dispatch(
        generalPurposeAction(actionTypes.ADD_FLASH_MESSAGE, error.response.data)
      );
    });
};
