import actionTypes from "../constants/types";
import isEmpty from "../validation/isEmpty";

const initialState = {
  isAuthenticated: false,
  user: {},
  institution: {},
  logo: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case actionTypes.SET_USER_INSTITUTION:
      return {
        ...state,
        institution: action.payload,
      };
    case actionTypes.SET_LOGO:
      return {
        ...state,
        logo: action.payload,
      };
    case actionTypes.CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        initialState,
      };
    default:
      return state;
  }
}
