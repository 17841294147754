import React from "react";
import { Link } from "react-router-dom";

const AuthLinks = () => {
  return (
    <div className="row m-login__form-sub">
      <div className="col m--align-right m-login__form-right">
        <Link
          to="reset-password"
          className="m-link"
          style={{ color: "#ff7738" }}
        >
          Forgotten Password
        </Link>
        &nbsp; | &nbsp;
        <Link to="resend-code" className="m-link" style={{ color: "#ff7738" }}>
          Resend Code
        </Link>
      </div>
    </div>
  );
};

export { AuthLinks };
