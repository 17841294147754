import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Title } from "../common";
import { TextFieldGroup, Button, Terms, RedirectLinks } from "../forms/guest";
import { sign_up } from "../../actions/auth-action";
import { displayToast } from "../common/Toaster";
import "./Auth.css";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";

const SignUp = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    email: "",
    password: "",
    name: "",
    confirm_password: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/dashboard");
    }

    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleChecked = () => {
    setAgree(!agree);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password,
      confirm_password: values.confirm_password,
      agree: agree,
    };
    dispatch(sign_up(user, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  return (
    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2">
      <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div className="m-login__container">
          <div className="login-section">
            <div className="m-login__signin">
              <Title title="Sign Up" />
              <form className="m-login__form m-form" onSubmit={onSubmit}>
                <TextFieldGroup
                  placeholder={"Name"}
                  value={values.name}
                  type={"text"}
                  name={"name"}
                  onChange={onChange}
                />
                <TextFieldGroup
                  placeholder={"Email"}
                  value={values.email}
                  name={"email"}
                  onChange={onChange}
                  type={"email"}
                />
                <TextFieldGroup
                  placeholder={"Phone"}
                  value={values.phone}
                  name={"phone"}
                  type={"text"}
                  onChange={onChange}
                />
                <TextFieldGroup
                  placeholder={"Password"}
                  value={values.password}
                  name={"password"}
                  onChange={onChange}
                  type={"password"}
                  className={"m-login__form-input--last"}
                />
                <TextFieldGroup
                  placeholder={"Retype Password"}
                  value={values.confirm_password}
                  name={"confirm_password"}
                  onChange={onChange}
                  type={"password"}
                  className={"m-login__form-input--last"}
                />
                <Terms name={"agree"} value={agree} onChange={handleChecked} />
                <div className="m-login__form-action">
                  <Button
                    title={"Sign Up"}
                    className={"custom-button btn--black"}
                    loading={isLoading}
                  />
                  <RedirectLinks
                    title={"Already Registered. Login"}
                    link={"/auth/login"}
                    className={"m-link m-link--dark m-login__account-link"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignUp);
