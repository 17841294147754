import React from "react";

const CustomSearch = ({ name, value, onChange, placeholder }) => {
  return (
    <React.Fragment>
      <input
        type="text"
        className={"form-control m-input"}
        placeholder={placeholder}
        name={name}
        value={value ? value : ""}
        onChange={onChange}
        autoComplete="off"
      />
    </React.Fragment>
  );
};

export { CustomSearch };
