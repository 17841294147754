import actionTypes from "../constants/types";

const initialState = {
  summary: [],
  donations: [],
  donation: {},
  donation_settings: [],
  donation_setting: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.GET_DONATION_SETTINGS:
      return {
        ...state,
        donation_settings: action.payload,
      };
    case actionTypes.GET_DONATION_SETTING:
      return {
        ...state,
        donation_setting: action.payload,
      };
    case actionTypes.GET_DONATIONS_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    case actionTypes.GET_DONATIONS:
      return {
        ...state,
        donations: action.payload,
      };
    case actionTypes.GET_DONATION:
      return {
        ...state,
        donation: action.payload,
      };
    default:
      return state;
  }
}
