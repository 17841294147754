import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { gets } from "../../actions/expenses-action";
import { generalPurposeAction } from "../../actions/common-action";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { TotalEIDColumns } from "../common/TableColumns";
import { months, years, expenseOptions } from "../../constants/mock";
import { Button, SelectListGroup } from "../forms/auth";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import moment from "moment";

const TotalExpenses = ({ match }) => {
  const today = new Date();
  const er = useSelector((state) => state.expenses);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [values, setValues] = useState({
    month: today.toLocaleString("default", { month: "long" }),
    year: today.getFullYear(),
  });
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [expense, setExpense] = useState({});

  useEffect(() => {
    const { params } = match;
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.EXPENSES)
    );
    dispatch(gets(params.month, params.year));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(gets(values.month, values.year));
  };

  useEffect(() => {
    const { expenses, loading } = er;
    setExpenses(expenses);
    setIsLoading(loading);
  }, [er]);

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    const expense = expenses.find((e) => e.id === id);
    setExpense(expense);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const tableColumns = TotalEIDColumns(
    modalToShow,
    modalTypes.VIEW_EXPENSE,
    "expenses",
    "edit-expense"
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(expenses).length > 0) {
      content = (
        <div className="col-md-12">
          <Table data={expenses} columns={tableColumns} />
        </div>
      );
    } else {
      content = (
        <EmptyBox
          link={"/expenses/create"}
          btnMessage={"Add Expense"}
          icon={"fa fa-money icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No Records Found"}
        />
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Total Expenses" options={expenseOptions} />
      <div className="m-content">
        <div className="m-portlet">
          <PortletHead title="Expenses" />
          <form
            className="m-form m-form--fit m-form--label-align-right"
            onSubmit={onSubmit}
          >
            <div className="form-group m-form__group row">
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.month}
                  name="month"
                  options={months}
                />
              </div>
              <div className="col-lg-5">
                <SelectListGroup
                  onChange={onChange}
                  value={values.year}
                  name="year"
                  options={years}
                />
              </div>
              <div className="col-lg-2" style={{ marginTop: "20px" }}>
                <Button title="Search" className="btn btn--black text-white" />
              </div>
            </div>
          </form>

          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.VIEW_EXPENSE ? (
        <Modal
          show={showModal}
          onClose={() => closeModal()}
          header="View Expense"
        >
          <div className="col-md-12">
            <div className="m-content">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <b>Month:</b>
                    </td>
                    <td>{expense.month}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Year:</b>
                    </td>
                    <td>{expense.year}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Staff ID:</b>
                    </td>
                    <td>{expense.member_staff_id}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Expense Type:</b>
                    </td>
                    <td>{expense.type}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Amount:</b>
                    </td>
                    <td>{expense.amount}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Description:</b>
                    </td>
                    <td>{expense.description}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Created By:</b>
                    </td>
                    <td>{expense.added_by}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Modified By:</b>
                    </td>
                    <td>{expense.updated_by}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date:</b>
                    </td>
                    <td>
                      {moment(expense.date).local().format("ddd, MMM Do YYYY")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Time:</b>
                    </td>
                    <td>
                      {moment(expense.created_at).local().format("HH.mm a")}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <Link
                        to={`/expenses/edit-expense/${expense.id}`}
                        className="btn btn--orange"
                        style={{ textDecoration: "none", color: "#FFF" }}
                      >
                        <i className="fa fa-edit"></i> Edit
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default TotalExpenses;
