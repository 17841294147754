import actionTypes from "../constants/types";

const initialState = {
  member: {},
  members: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case actionTypes.GET_MEMBER:
      return {
        ...state,
        member: action.payload,
      };
    case actionTypes.GENERAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
