import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  get_expense_settings,
  create_expense_setting,
} from "../../actions/expenses-action";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { enableRipple } from "@syncfusion/ej2-base";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { expenseSettingsOptions } from "../../constants/mock";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
enableRipple(true);

const CreateExpenseSetting = ({ history }) => {
  const dispatch = useDispatch();
  const er = useSelector((state) => state.expenses);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [parentId, setParentId] = useState(null);
  const [parentName, setParentName] = useState("");
  let expenseTypes = [];
  const cssClass = "custom";
  const node = useRef();

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.SETTINGS)
    );
    dispatch(get_expense_settings());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { value } = e.target;
    setType(value);
  };

  const onClickAddParentId = (e) => {
    const { id, text } = e.nodeData;
    setParentId(id);
    setParentName(text);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const setting = {
      type: type,
      parent_id: parentId,
    };
    dispatch(create_expense_setting(setting, history));
  };

  useEffect(() => {
    const { expense_settings } = er;
    setTypes(expense_settings);
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading, er]);

  if (!isEmpty(types)) {
    types.map((t) =>
      expenseTypes.push(
        t.parent_id === 0
          ? { id: t.id, name: t.type, hasChild: true, expanded: true }
          : {
              id: t.id,
              pid: t.parent_id,
              name: t.type,
              hasChild: true,
              expanded: false,
            }
      )
    );
  }

  const field = {
    dataSource: expenseTypes,
    id: "id",
    text: "name",
    parentID: "pid",
    hasChildren: "hasChild",
  };

  return (
    <React.Fragment>
      <SubHeader
        options={expenseSettingsOptions}
        title="Add Expenditure Type"
      />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Expenditure Setup" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <label>Select Parent Type (Click to select)</label>
                      <TreeViewComponent
                        fields={field}
                        cssClass={cssClass}
                        nodeSelected={onClickAddParentId}
                        ref={node}
                        hide
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <TextFieldGroup
                          label="Selected Type"
                          type="text"
                          onChange={onClickAddParentId}
                          value={parentName}
                          name="parentName"
                          placeholder="Select a parent type on the left side"
                          disabled="disabled"
                        />
                      </div>
                      <div className="row mt-3">
                        <TextFieldGroup
                          label="Type"
                          type="text"
                          onChange={onChange}
                          value={type}
                          name="type"
                          placeholder="Expenditure type"
                        />
                      </div>
                      <div className="row mt-3">
                        <Button
                          title="Save"
                          className={"btn btn--black text-white"}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateExpenseSetting);
