import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  get_expense_settings,
  delete_expense_setting,
} from "../../actions/expenses-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { expenseSettingsOptions } from "../../constants/mock";
import { SettingsColumns } from "../common/TableColumns";

const ExpenseSettings = () => {
  const er = useSelector((state) => state.expenses);
  const dispatch = useDispatch();
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.SETTINGS)
    );
    dispatch(get_expense_settings());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { expense_settings, loading } = er;
    if (!isEmpty(expense_settings)) {
      setSettings(expense_settings);
    }
    setIsLoading(loading);
  }, [er]);

  const onDeleteClick = (id) => {
    dispatch(delete_expense_setting(id));
  };

  const tableColumns = SettingsColumns(onDeleteClick);

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(settings).length === 0) {
      content = (
        <EmptyBox
          link={"/expense-settings/create"}
          btnMessage={"Create Expenditure Type"}
          icon={"fa fa-money icon-large"}
          btnIcon={"fa fa-plus"}
          info={"You have not created any expenditure type"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={settings} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader
        title={"Expenditure Settings"}
        options={expenseSettingsOptions}
      />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Expenditure Types" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExpenseSettings;
