import React from "react";

const TextAreaFieldGroup = ({
  label,
  name,
  placeholder,
  info,
  value,
  onChange,
  autoComplete,
  rows,
  disabled,
}) => {
  return (
    <React.Fragment>
      <label>{label}</label>
      <textarea
        className={"form-control m-input"}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        rows={rows}
        disabled={disabled}
      />
      {info && <span className="m-form__help">{info}</span>}
    </React.Fragment>
  );
};

export { TextAreaFieldGroup };
