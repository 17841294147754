import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { get_loan_summary } from "../../actions/loans-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { LoansSummaryColumns } from "../common/TableColumns";

const Loans = () => {
  const lr = useSelector((state) => state.loans);
  const dispatch = useDispatch();
  const [summary, setSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.LOANS)
    );
    dispatch(get_loan_summary());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { summary, loading } = lr;
    if (!isEmpty(summary)) {
      setSummary(summary);
    }
    setIsLoading(loading);
  }, [lr]);

  const options = [
    {
      link: "loans/create",
      title: "Grant Loan",
      icon: "m-nav__link-icon fa fa-plus",
    },
  ];

  const tableColumns = LoansSummaryColumns();

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(summary).length === 0) {
      content = (
        <EmptyBox
          link={"/loans/create"}
          btnMessage={"Grant Loan"}
          icon={"fa fa-euro icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No loans has been granted"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={summary} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Loans"} options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Loans Summary" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loans;
