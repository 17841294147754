import React from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { CustomSearch, Pagination } from "./index";
import BTable from "react-bootstrap/Table";

const Table = ({ data, columns }) => {
  const ColumnFilter = ({ column: { filterValue, setFilter, filter } }) => {
    return (
      <CustomSearch
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${filter ? filter : ""}...`}
      />
    );
  };

  const filterTypes = {
    year: (rows, id, filterValue) => {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined &&
          Number(filterValue) &&
          new Date(rowValue) &&
          new Date(rowValue).isValid()
          ? new Date(rowValue).getFullYear() === Number(filterValue)
          : true;
      });
    },
    text: (rows, id, filterValue) => {
      return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
          : true;
      });
    },
  };

  const defaultColumn = {
    Filter: ColumnFilter,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    // hook for filters: comes before the use sortBy
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChangeInSelect = (e) => {
    setPageSize(Number(e.target.value));
  };

  const onChangeInInput = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(page);
  };

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  return (
    <React.Fragment>
      <BTable striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const {
                  render,
                  getHeaderProps,
                  isSorted,
                  isSortedDesc,
                  getSortByToggleProps,
                  canFilter,
                } = column;
                const extraClass = isSorted
                  ? isSortedDesc
                    ? "desc"
                    : "asc"
                  : "";
                return (
                  <th
                    key={`th-${i}`}
                    className={extraClass}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    <div {...getHeaderProps(getSortByToggleProps())}>
                      {render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <div>{canFilter ? render("Filter") : null}</div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ textAlign: "center" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map((column) => (
                <td
                  {...column.getFooterProps()}
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </BTable>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        nextPage={nextPage}
        pageOptions={pageOptions}
        onChangeInInput={onChangeInInput}
        onChangeInSelect={onChangeInSelect}
        canNextPage={canNextPage}
        pageCount={pageCount}
        previousPage={previousPage}
      />
    </React.Fragment>
  );
};

export { Table };
