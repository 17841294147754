import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import moment from "moment";
import { get } from "../../actions/members-action";
import {
  get_member_contributions,
  export_member_contributions,
} from "../../actions/contributions-action";
import { get_member_loans } from "../../actions/loans-action";
import {
  get_member_donations,
  export_member_donations,
} from "../../actions/donations-action";
import {
  get_member_expenses,
  export_member_expenses,
} from "../../actions/expenses-action";
import {
  get_member_incomes,
  export_member_incomes,
} from "../../actions/incomes-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { membersOptions } from "../../constants/mock";
import Modal from "../layout/Modal";
import modalTypes from "../../constants/modal-types";
import { displayToast } from "../common/Toaster";
import { MemberCEDIColumns, MemberLoans } from "../common/TableColumns";

const ViewMember = ({ match }) => {
  const members = useSelector((state) => state.members);
  const cr = useSelector((state) => state.contributions);
  const lr = useSelector((state) => state.loans);
  const dr = useSelector((state) => state.donations);
  const er = useSelector((state) => state.expenses);
  const ir = useSelector((state) => state.incomes);
  const dispatch = useDispatch();
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [contributions, setContributions] = useState([]);
  const [loans, setLoans] = useState([]);
  const [donations, setDonations] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [incomes, setIncomes] = useState([]);

  useEffect(() => {
    const { params } = match;
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.MEMBERS)
    );
    dispatch(get(params.id));
    dispatch(get_member_contributions(params.staff_id));
    dispatch(get_member_loans(params.staff_id));
    dispatch(get_member_donations(params.staff_id));
    dispatch(get_member_expenses(params.staff_id));
    dispatch(get_member_incomes(params.staff_id));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { member, loading } = members;
    if (!isEmpty(member)) {
      setMember(member);
    }
    setIsLoading(loading);
    const { contributions } = cr;
    if (!isEmpty(contributions)) {
      setContributions(contributions);
    }
    const { loans } = lr;
    if (!isEmpty(loans)) {
      setLoans(loans);
    }
    const { donations } = dr;
    if (!isEmpty(donations)) {
      setDonations(donations);
    }
    const { expenses } = er;
    if (!isEmpty(expenses)) {
      setExpenses(expenses);
    }
    const { incomes } = ir;
    if (!isEmpty(incomes)) {
      setIncomes(incomes);
    }
  }, [members, cr, lr, dr, er, ir]);

  const modalToShow = (type) => {
    switch (type) {
      case modalTypes.VIEW_MEMBER_CONTRIBUTIONS:
        if (!isEmpty(contributions)) {
          setShowModal(true);
          setModalType(type);
        } else {
          displayToast("Member has no contribution record", false);
        }
        break;
      case modalTypes.VIEW_MEMBER_LOANS:
        if (!isEmpty(loans)) {
          setShowModal(true);
          setModalType(type);
        } else {
          displayToast("Member has no loans record", false);
        }
        break;
      case modalTypes.VIEW_MEMBER_DONATIONS:
        if (!isEmpty(donations)) {
          setShowModal(true);
          setModalType(type);
        } else {
          displayToast("Member has no donations record", false);
        }
        break;
      case modalTypes.VIEW_MEMBER_EXPENSES:
        if (!isEmpty(expenses)) {
          setShowModal(true);
          setModalType(type);
        } else {
          displayToast("Member has no expenses record", false);
        }
        break;
      case modalTypes.VIEW_MEMBER_INCOME:
        if (!isEmpty(incomes)) {
          setShowModal(true);
          setModalType(type);
        } else {
          displayToast("Member has no income record", false);
        }
        break;
      default:
        return;
    }
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onExportClick = (id, type) => {
    switch (type) {
      case modalTypes.VIEW_MEMBER_CONTRIBUTIONS:
        export_member_contributions(id);
        break;
      case modalTypes.VIEW_MEMBER_EXPENSES:
        export_member_expenses(id);
        break;
      case modalTypes.VIEW_MEMBER_DONATIONS:
        export_member_donations(id);
        break;
      case modalTypes.VIEW_MEMBER_INCOME:
        export_member_incomes(id);
        break;
      default:
        return null;
    }
  };

  const tableColumns = MemberCEDIColumns();
  const tableColumnsLoans = MemberLoans();

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (!isLoading) {
      if (isEmpty(member)) {
        content = (
          <EmptyBox
            link={"/members/create"}
            btnMessage={"Add Member"}
            icon={"flaticon-user icon-large"}
            btnIcon={"fa fa-plus"}
            info={"No record found"}
          />
        );
      } else {
        content = (
          <div className="col-md-12">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <b>ID:</b>
                  </td>
                  <td>{member.member_staff_id}</td>
                </tr>
                <tr>
                  <td>
                    <b>Name:</b>
                  </td>
                  <td>{member.member_name}</td>
                </tr>
                <tr>
                  <td>
                    <b>Phone:</b>
                  </td>
                  <td>{member.member_phone}</td>
                </tr>
                <tr>
                  <td>
                    <b>Email:</b>
                  </td>
                  <td>{member.member_email}</td>
                </tr>
                <tr>
                  <td>
                    <b>Date Created:</b>
                  </td>
                  <td>
                    {moment(member.created_at)
                      .local()
                      .format("ddd, MMM Do YYYY")}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Time Created:</b>
                  </td>
                  <td>{moment(member.created_at).local().format("HH.mm a")}</td>
                </tr>
                <tr>
                  <td>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-primary text-white"
                        onClick={() =>
                          modalToShow(modalTypes.VIEW_MEMBER_CONTRIBUTIONS)
                        }
                      >
                        <i className="fa fa-bank"></i> Contributions
                      </button>
                      <button
                        className="btn btn-info text-white"
                        onClick={() =>
                          modalToShow(modalTypes.VIEW_MEMBER_LOANS)
                        }
                      >
                        <i className="fa fa-euro"></i> Loans
                      </button>
                      <button
                        className="btn btn-success text-white"
                        onClick={() =>
                          modalToShow(modalTypes.VIEW_MEMBER_EXPENSES)
                        }
                      >
                        <i className="fa fa-money"></i> Expenses
                      </button>
                      <button
                        className="btn btn-warning text-white"
                        onClick={() =>
                          modalToShow(modalTypes.VIEW_MEMBER_INCOME)
                        }
                      >
                        <i className="fa fa-bitcoin"></i> Income
                      </button>
                      <button
                        className="btn btn--black text-white"
                        onClick={() =>
                          modalToShow(modalTypes.VIEW_MEMBER_DONATIONS)
                        }
                      >
                        <i className="fa fa-dollar"></i> Donations
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }
    }
  }

  return (
    <React.Fragment>
      <SubHeader title={"Members"} options={membersOptions} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Members Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.VIEW_MEMBER_CONTRIBUTIONS ? (
        <Modal
          show={showModal}
          onClose={closeModal}
          header={`${member.member_name}'s Contributions`}
        >
          <div className="row m-row--no-padding m-row--col-separator-xl">
            <div className="col mb-3">
              <span className="pr-3">
                <button
                  className="btn btn-primary text-white"
                  onClick={() =>
                    onExportClick(
                      member.member_staff_id,
                      modalTypes.VIEW_MEMBER_CONTRIBUTIONS
                    )
                  }
                >
                  <i className="fa fa-download"></i>Download Excel{" "}
                  <i className="fa fa-file-excel-o"></i>
                </button>
              </span>
            </div>
            <Table data={contributions} columns={tableColumns} />
          </div>
        </Modal>
      ) : null}
      {showModal && modalType === modalTypes.VIEW_MEMBER_LOANS ? (
        <Modal
          show={showModal}
          onClose={closeModal}
          header={`${member.member_name}'s Loans`}
        >
          <div className="row m-row--no-padding m-row--col-separator-xl">
            <div className="col mb-3">
              {/* <span className="pr-3">
                    <button
                      className="btn btn-info text-white"
                      onClick={() =>
                        onExportClick(
                          member.member_staff_id,
                          modalTypes.VIEW_MEMBER_LOANS
                        )
                      }
                    >
                      <i className="fa fa-download"></i>Download Excel{" "}
                      <i className="fa fa-file-excel-o"></i>
                    </button>
                  </span> */}
            </div>
            <Table data={loans} columns={tableColumnsLoans} />
          </div>
        </Modal>
      ) : null}
      {showModal && modalType === modalTypes.VIEW_MEMBER_EXPENSES ? (
        <Modal
          show={showModal}
          onClose={closeModal}
          header={`${member.member_name}'s Expenses`}
        >
          <div className="row m-row--no-padding m-row--col-separator-xl">
            <div className="col mb-3">
              <span className="pr-3">
                <button
                  className="btn btn-success text-white"
                  onClick={() =>
                    onExportClick(
                      member.member_staff_id,
                      modalTypes.VIEW_MEMBER_EXPENSES
                    )
                  }
                >
                  <i className="fa fa-download"></i>Download Excel{" "}
                  <i className="fa fa-file-excel-o"></i>
                </button>
              </span>
            </div>
            <Table data={expenses} columns={tableColumns} />
          </div>
        </Modal>
      ) : null}
      {showModal && modalType === modalTypes.VIEW_MEMBER_INCOME ? (
        <Modal
          show={showModal}
          onClose={closeModal}
          header={`${member.member_name}'s Income`}
        >
          <div className="row m-row--no-padding m-row--col-separator-xl">
            <div className="col mb-3">
              <span className="pr-3">
                <button
                  className="btn btn-warning text-white"
                  onClick={() =>
                    onExportClick(
                      member.member_staff_id,
                      modalTypes.VIEW_MEMBER_INCOME
                    )
                  }
                >
                  <i className="fa fa-download"></i>Download Excel{" "}
                  <i className="fa fa-file-excel-o"></i>
                </button>
              </span>
            </div>
            <Table data={incomes} columns={tableColumns} />
          </div>
        </Modal>
      ) : null}
      {showModal && modalType === modalTypes.VIEW_MEMBER_DONATIONS ? (
        <Modal
          show={showModal}
          onClose={closeModal}
          header={`${member.member_name}'s Donations`}
        >
          <div className="row m-row--no-padding m-row--col-separator-xl">
            <div className="col mb-3">
              <span className="pr-3">
                <button
                  className="btn btn--black text-white"
                  onClick={() =>
                    onExportClick(
                      member.member_staff_id,
                      modalTypes.VIEW_MEMBER_DONATIONS
                    )
                  }
                >
                  <i className="fa fa-download"></i>Download Excel{" "}
                  <i className="fa fa-file-excel-o"></i>
                </button>
              </span>
            </div>
            <Table data={donations} columns={tableColumns} />
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default ViewMember;
