import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_logo, update } from "../../actions/institution-action";
import {
  Button,
  SelectListGroup,
  TextFieldGroup,
  FileInputGroup,
} from "../forms/auth";
import Modal from "../layout/Modal";
import modalTypes from "../../constants/modal-types";
import { displayToast } from "../common/Toaster";
import isEmpty from "../../validation/isEmpty";

const Institute = ({ institution }) => {
  const dispatch = useDispatch();
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    name: !isEmpty(institution) ? institution.name : "",
    email: !isEmpty(institution) ? institution.email : "",
    phone: !isEmpty(institution) ? institution.phone : "",
    location: !isEmpty(institution) ? institution.location : "",
    sender_id: !isEmpty(institution) ? institution.sender_id : "",
    api_key: !isEmpty(institution) ? institution.api_key : "",
    payment_type: !isEmpty(institution) ? institution.payment_type : "",
  });

  const paymentTypes = [
    { label: "Uniform Payment", value: 1 },
    { label: "Fixed-Rate Payment", value: 2 },
    { label: "Non-Uniform Payment", value: 3 },
  ];

  const get_payment_type = (type) => {
    const pt = paymentTypes.find((p) => p.value === type);
    return pt.label;
  };

  const modalToShow = (type) => {
    setShowModal(true);
    setModalType(type);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const onSubmitLogo = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("file", file, file.name);
    dispatch(set_logo(fd));
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { id } = institution;
    const data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      location: values.location,
      sender_id: values.sender_id,
      api_key: values.api_key,
      payment_type: values.payment_type,
    };
    dispatch(update(id, data));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body bg-black text-white mb-3">
              <div className="row">
                <div className="col-4 col-md-3 m-auto">
                  <img
                    className="mx-auto d-block img-fluid"
                    src={institution.logo}
                    style={{
                      width: "200px",
                      margin: "auto",
                      display: "block",
                    }}
                    alt="logo"
                  />
                </div>
              </div>
              <div className="text-center">
                <div className="mt-2">
                  <button
                    className="btn btn-outline-secondary btn-lg"
                    onClick={() => modalToShow(modalTypes.SET_LOGO)}
                  >
                    Add Logo
                  </button>
                </div>
                <h1 className="display-4 text-center">{institution.name}</h1>
                <p className="lead text-center">
                  <span>Email: {institution.email}</span>{" "}
                  <span>Location: {institution.location}</span>
                </p>
                <p className="lead text-center">
                  <span>Phone: {institution.phone}</span>{" "}
                  <span>Sender ID: {institution.sender_id}</span>
                </p>
                <p className="lead text-center">
                  <span>
                    Payment Type: {get_payment_type(institution.payment_type)}
                  </span>
                </p>
                <p className="lead text-center">
                  <button
                    className="btn btn-outline-secondary btn-lg"
                    onClick={() => modalToShow(modalTypes.EDIT_INST)}
                  >
                    Edit Bank
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.SET_LOGO ? (
        <Modal show={showModal} onClose={closeModal} header="Set Logo">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmitLogo}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-12">
                  <FileInputGroup
                    accept=".png, .jpeg, .jpg"
                    label="Choose file from your directory. Must be .png file"
                    name="file"
                    id="customFile"
                    onChange={handleFileUpload}
                    type="file"
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title="Set Logo"
                  className="btn btn--black text-white"
                  loading={isLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}

      {showModal && modalType === modalTypes.EDIT_INST ? (
        <Modal show={showModal} onClose={closeModal} header="Edit Institution">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Name of Institution"
                    onChange={onChange}
                    value={values.name}
                    type="text"
                    name="name"
                    placeholder="Enter name of Institution"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Email"
                    type="text"
                    onChange={onChange}
                    value={values.email}
                    name="email"
                    placeholder="Enter email of institution"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Phone"
                    onChange={onChange}
                    value={values.phone}
                    name="phone"
                    type="text"
                    placeholder="Enter phone of institution"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Location"
                    onChange={onChange}
                    value={values.location}
                    name="location"
                    type="text"
                    placeholder="Enter location of institution"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Sender ID"
                    onChange={onChange}
                    value={values.sender_id}
                    name="sender_id"
                    type="text"
                    placeholder="Enter Sender ID of institution"
                    min="11"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="API Key"
                    onChange={onChange}
                    value={values.api_key}
                    name="api_key"
                    type="text"
                    placeholder="Enter API key of institution"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <SelectListGroup
                    label="Payment Type"
                    onChange={onChange}
                    value={`${values.payment_type}`}
                    name="payment_type"
                    placeholder="Enter Payment type of institution"
                    options={paymentTypes}
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title="Submit"
                  className="btn btn--black text-white"
                  loading={isLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Institute;
