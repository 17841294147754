import React from "react";

const SelectListGroup = ({ label, name, value, info, onChange, options }) => {
  const selectOptions = options.map((option) => (
    <option key={option.label} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <React.Fragment>
      <label>{label}</label>
      <select
        className={"form-control m-input"}
        name={name}
        value={value}
        onChange={onChange}
      >
        <option value={""}>--Select One--</option>
        {selectOptions}
      </select>
      {info && <span className="m-form__help">{info}</span>}
    </React.Fragment>
  );
};

export { SelectListGroup };
