import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { create } from "../../actions/members-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { SubHeader, PortletHead } from "../common";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";

const CreateMember = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    name: "",
    staff_id: "",
    email: "",
    phone: "",
    amount: "",
    payment_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { payment_type } = auth.institution;

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const member = {
      name: values.name,
      staff_id: values.staff_id,
      email: values.email,
      phone: values.phone,
      amount: values.amount,
      payment_type: payment_type,
    };
    dispatch(create(member, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading]);

  const options = [
    { link: "members", title: "Members", icon: "m-nav__link-icon fa fa-users" },
    {
      link: "members/upload",
      title: "Upload Members",
      icon: "m-nav__link-icon fa fa-upload",
    },
  ];

  return (
    <React.Fragment>
      <SubHeader options={options} title="Create Member" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Add  member to institution" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Name of Member"
                        onChange={onChange}
                        value={values.name}
                        type="text"
                        name="name"
                        placeholder="Enter name of Member"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Staff ID"
                        type="text"
                        onChange={onChange}
                        value={values.staff_id}
                        name="staff_id"
                        placeholder="Enter Staff ID of member"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        type="text"
                        label="Email"
                        onChange={onChange}
                        value={values.email}
                        name="email"
                        placeholder="Enter Email of member"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Phone"
                        type="text"
                        onChange={onChange}
                        value={values.phone}
                        name="phone"
                        placeholder="Enter phone of member"
                      />
                    </div>
                  </div>
                  {payment_type === 2 || payment_type === "2" ? (
                    <div className="form-group m-form__group row">
                      <div className="col-lg-6">
                        <TextFieldGroup
                          label="Amount in GHS"
                          onChange={onChange}
                          type="text"
                          value={values.amount}
                          name="amount"
                          placeholder="Enter amount member will pay every month"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="m-form__actions">
                    <Button
                      title="Submit"
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateMember);
