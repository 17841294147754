import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import { gets } from "../../actions/members-action";
import { get_loan_settings, create } from "../../actions/loans-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import modalTypes from "../../constants/modal-types";
import Modal from "../layout/Modal";
import { GrantLoanColumns } from "../common/TableColumns";
import { Button, TextFieldGroup, SelectListGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import { months, years } from "../../constants/mock";

const CreateLoan = ({ history }) => {
  const today = new Date();
  const mr = useSelector((state) => state.members);
  const lr = useSelector((state) => state.loans);
  const fr = useSelector((state) => state.flash);
  const ld = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [staffId, setStaffId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    loan_type: "",
    amount_loaned: "",
    time: "",
    month: today.toLocaleString("default", { month: "long" }),
    year: today.getFullYear(),
  });
  const [loanTypes, setLoanTypes] = useState([]);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.LOANS)
    );
    dispatch(gets());
    dispatch(get_loan_settings());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { members, loading } = mr;
    const { loan_settings } = lr;
    const { success, message } = fr;
    const { isLoading } = ld;
    if (!isEmpty(members)) {
      setMembers(members);
    }
    if (!isEmpty(loan_settings)) {
      setLoanTypes(loan_settings);
    }
    setIsLoading(loading);
    setButtonLoading(isLoading);
    displayToast(message, success);
  }, [mr, lr, fr, ld]);

  const modalToShow = (staffId, type) => {
    setShowModal(true);
    setModalType(type);
    setStaffId(staffId);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const loan = {
      password: values.password,
      staff_id: staffId,
      loan_type: values.loan_type,
      amount_loaned: values.amount_loaned,
      time: values.time,
      month: values.month,
      year: values.year,
    };
    dispatch(create(loan, history));
  };

  const options = [
    { link: "loans", title: "Loans", icon: "m-nav__link-icon fa fa-money" },
  ];

  const tableColumns = GrantLoanColumns(modalToShow, modalTypes.CREATE_LOAN);

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(members).length === 0) {
      content = (
        <EmptyBox
          link={"/members/create"}
          btnMessage={"Add Member"}
          icon={"fa fa-user-o icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No member is registered"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={members} columns={tableColumns} />
        </div>
      );
    }
  }

  let selectListLoanTypes = [];
  if (!isEmpty(loanTypes)) {
    selectListLoanTypes = loanTypes.map((type) => ({
      label: type.type,
      value: type.id,
    }));
  }

  return (
    <React.Fragment>
      <SubHeader title={"Loans"} options={options} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Grant Loan" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.CREATE_LOAN ? (
        <Modal show={showModal} onClose={closeModal} header="Grant Member Loan">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Your Password"
                    onChange={onChange}
                    value={values.password}
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Staff ID"
                    onChange={onChange}
                    type="text"
                    value={staffId}
                    name="staff_id"
                    placeholder="Enter the member Staff ID"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <SelectListGroup
                    label="Select Loan Type"
                    onChange={onChange}
                    value={values.loan_type}
                    name="loan_type"
                    options={selectListLoanTypes}
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Amount in GHS"
                    type="text"
                    onChange={onChange}
                    value={values.amount_loaned}
                    name="amount_loaned"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <SelectListGroup
                    label="Select Month"
                    onChange={onChange}
                    value={values.month}
                    name="month"
                    options={months}
                  />
                </div>
                <div className="col-lg-6">
                  <SelectListGroup
                    label="Select Year"
                    onChange={onChange}
                    value={values.year}
                    name="year"
                    options={years}
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Time in Months"
                    onChange={onChange}
                    value={values.time}
                    name="time"
                    placeholder="Enter the time in months for interest"
                    type="number"
                    min="1"
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title="Grant Loan"
                  className={"btn btn--black text-white"}
                  loading={buttonLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(CreateLoan);
