import React from "react";

const Button = ({ title, className, loading }) => {
  return (
    <React.Fragment>
      <button
        className={`${className} ${
          loading ? "m-loader m-loader--light m-loader--right" : ""
        }`}
        disabled={loading}
      >
        {title}
      </button>
    </React.Fragment>
  );
};

export { Button };
