import React from "react";

const TextFieldGroup = ({
  type,
  placeholder,
  name,
  value,
  onChange,
  autoComplete,
  info,
  className,
}) => {
  return (
    <div className="form-group m-form__group">
      <input
        type={type}
        className={`form-control m-input ${className}`}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      {info && <small className="form-text text-muted">{info}</small>}
    </div>
  );
};

export { TextFieldGroup };
