import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_totals, get_yearly_summary } from "../../actions/dashboard-action";
import { generalPurposeAction } from "../../actions/common-action";
// import { SubHeader, Widget, HighChart } from "../common";
import { SubHeader, Widget } from "../common";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { years } from "../../constants/mock";
import { SelectListGroup } from "../forms/auth";
import isEmpty from "../../validation/isEmpty";

const Dashboard = () => {
  const today = new Date();
  const dispatch = useDispatch();
  const dash = useSelector((state) => state.dashboard);
  const [total, setTotal] = useState({
    members: "",
    contributions: "",
    loans: "",
    expenses: "",
    incomes: "",
    donations: "",
  });
  // const [summary, setSummary] = useState({
  //   members: "",
  //   contributions: "",
  //   loans: "",
  //   expenses: "",
  //   incomes: "",
  //   donations: "",
  // });
  const [year, setYear] = useState(today.getFullYear());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.DASHBOARD)
    );
    dispatch(get_totals(year));
    dispatch(get_yearly_summary(year));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeYear = (e) => {
    const { value } = e.target;
    setYear(value);
    dispatch(get_totals(value));
    dispatch(get_yearly_summary(value));
  };

  useEffect(() => {
    const {
      total_members,
      total_contributions,
      total_loans,
      total_donations,
      total_expenses,
      total_incomes,
      loading,
    } = dash;
    setTotal({
      members: total_members,
      contributions: total_contributions,
      loans: total_loans,
      expenses: total_expenses,
      incomes: total_incomes,
      donations: total_donations,
    });
    setIsLoading(loading);
  }, [dash]);

  // useEffect(() => {
  //   const {
  //     contributions_summary,
  //     loans_summary,
  //     donations_summary,
  //     expenses_summary,
  //     incomes_summary,
  //     loading,
  //   } = dash;
  //   setSummary({
  //     contributions: contributions_summary,
  //     loans: loans_summary,
  //     expenses: expenses_summary,
  //     incomes: incomes_summary,
  //     donations: donations_summary,
  //   });
  //   setIsLoading(loading);
  // }, [dash]);

  let c,
    l,
    d,
    e,
    i = 0;
  if (!isEmpty(total)) {
    const sum =
      parseFloat(total.contributions) +
      parseFloat(total.loans) +
      parseFloat(total.donations) +
      parseFloat(total.expenses) +
      parseFloat(total.incomes);

    c = ((parseFloat(total.contributions) / sum) * 100).toFixed(2);
    l = ((parseFloat(total.loans) / sum) * 100).toFixed(2);
    d = ((parseFloat(total.donations) / sum) * 100).toFixed(2);
    e = ((parseFloat(total.expenses) / sum) * 100).toFixed(2);
    i = ((parseFloat(total.incomes) / sum) * 100).toFixed(2);
  }

  const content = (
    <React.Fragment>
      <Widget
        loading={isLoading}
        color="m--font-brand"
        title="Contributions"
        value={`GHS ${total.contributions}`}
        progress="m--bg-brand"
        percentage={c !== "NaN" ? c : 0}
        description={`Total contributions (${year})`}
      />
      <Widget
        loading={isLoading}
        color={"m--font-danger"}
        title="Loans"
        value={`GHS ${total.loans}`}
        progress="m--bg-danger"
        percentage={l !== "NaN" ? l : 0}
        description={`Total loans (${year})`}
      />
      <Widget
        loading={isLoading}
        color={"m--font-info"}
        title="Donations"
        value={`GHS ${total.donations}`}
        progress="m--bg-info"
        percentage={d !== "NaN" ? d : 0}
        description={`Total donations (${year})`}
      />
      <Widget
        loading={isLoading}
        color={"m--font-success"}
        title="Expenses"
        value={`GHS ${total.expenses}`}
        progress="m--bg-success"
        percentage={e !== "NaN" ? e : 0}
        description={`Total expenditure (${year})`}
      />
      <Widget
        loading={isLoading}
        color={"m--font-warning"}
        title="Income"
        value={`GHS ${total.incomes}`}
        progress="m--bg-warning"
        percentage={i !== "NaN" ? i : 0}
        description={`Total income (${year})`}
      />
      <Widget
        loading={isLoading}
        color={"m--font-primary"}
        title="Members"
        value={total.members}
        progress="m--bg-primary"
        percentage={parseInt(total.members) !== 0 ? 100 : 0}
        description="Total members"
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <SubHeader title="Dashboard" options={null} />
      <div className="m-content">
        <div className="form-group m-form__group row">
          <div className="col-lg-5">
            <SelectListGroup
              onChange={onChangeYear}
              value={year}
              name="year"
              options={years}
            />
          </div>
        </div>
        <div className="m-portlet">
          <div className="row m-row--no-padding m-row--col-separator-xl">
            {content}
          </div>
        </div>
        <div className="m-portlet">
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12 col-lg-12 col-sm-12">
                {/* <HighChart summary={summary} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
