import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { upload, get_income_settings } from "../../actions/incomes-action";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { enableRipple } from "@syncfusion/ej2-base";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import { incomeOptions } from "../../constants/mock";
import { SubHeader, PortletHead } from "../common";
import {
  Button,
  TextFieldGroup,
  SelectListGroup,
  FileInputGroup,
} from "../forms/auth";
import { displayToast } from "../common/Toaster";
import isEmpty from "../../validation/isEmpty";
import { months, years } from "../../constants/mock";
enableRipple(true);

const TEMPLATE_URL = "http://welfare.test/files/income_template.xlsx";

const UploadIncomes = ({ history }) => {
  const today = new Date();
  const dispatch = useDispatch();
  const ir = useSelector((state) => state.incomes);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    month: today.toLocaleString("default", { month: "long" }),
    year: today.getFullYear(),
    sheet: "",
    start_row: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [excel, setExcel] = useState("");
  const [types, setTypes] = useState([]);
  const [typeId, setTypeId] = useState(null);
  const [parentName, setParentName] = useState("");
  let incomeTypes = [];
  const cssClass = "custom";
  const node = useRef();

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    dispatch(get_income_settings());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      setExcel(e.target.files[0]);
    }
  };

  const onClickAddParentId = (e) => {
    const { id, text } = e.nodeData;
    setTypeId(id);
    setParentName(text);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("excel", excel, excel.name);
    fd.append("type", typeId);
    fd.append("month", values.month);
    fd.append("year", values.year);
    fd.append("sheet", values.sheet);
    fd.append("start_row", values.start_row);
    dispatch(upload(fd, history));
  };

  useEffect(() => {
    const { income_settings } = ir;
    setTypes(income_settings);
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading, ir]);

  if (!isEmpty(types)) {
    types.map((t) =>
      incomeTypes.push(
        t.parent_id === 0
          ? { id: t.id, name: t.type, hasChild: true, expanded: true }
          : {
              id: t.id,
              pid: t.parent_id,
              name: t.type,
              hasChild: true,
              expanded: false,
            }
      )
    );
  }

  const field = {
    dataSource: incomeTypes,
    id: "id",
    text: "name",
    parentID: "pid",
    hasChildren: "hasChild",
  };

  return (
    <React.Fragment>
      <SubHeader options={incomeOptions} title="Upload Income" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Upload income" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <label>Select Parent Type (Click to select)</label>
                      <TreeViewComponent
                        fields={field}
                        cssClass={cssClass}
                        nodeSelected={onClickAddParentId}
                        ref={node}
                        hide
                      />
                    </div>

                    <div className="col-lg-6">
                      <div className="row">
                        <a
                          href={TEMPLATE_URL}
                          className="btn btn--orange text-white btn-lg"
                        >
                          <i className="fa fa-file-excel-o" /> Download Sample
                          Template
                        </a>
                      </div>
                      <div className="row mt-3">
                        <TextFieldGroup
                          label="Selected Type"
                          type="text"
                          onChange={onClickAddParentId}
                          value={parentName}
                          name="parentName"
                          placeholder="Select Income Type on the left side"
                          disabled="disabled"
                        />
                      </div>
                      <div className="row mt-3">
                        <FileInputGroup
                          accept=".xls, .xlsx, .csv"
                          label="Excel File (Must be .xls, .xlsx or .csv file)"
                          name="excel"
                          id="customFile"
                          type="file"
                          onChange={handleFileUpload}
                        />
                      </div>
                      <div className="row mt-3">
                        <SelectListGroup
                          label="Month"
                          onChange={onChange}
                          value={values.month}
                          name="month"
                          options={months}
                        />
                      </div>
                      <div className="row mt-3">
                        <SelectListGroup
                          label="Year"
                          onChange={onChange}
                          value={values.year}
                          name="year"
                          options={years}
                        />
                      </div>
                      <div className="row mt-3">
                        <TextFieldGroup
                          label="Sheet Number"
                          onChange={onChange}
                          value={values.sheet}
                          name="sheet"
                          placeholder="Eg 1"
                        />
                      </div>
                      <div className="row mt-3">
                        <TextFieldGroup
                          label="Start Row"
                          onChange={onChange}
                          value={values.start_row}
                          name="start_row"
                          placeholder="Eg 2"
                        />
                      </div>
                      <div className="row mt-3">
                        <Button
                          title={"Upload Income"}
                          className={"btn btn--black text-white"}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UploadIncomes);
