import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { create } from "../../actions/institution-action";
import { generalPurposeAction } from "../../actions/common-action";
import actionTypes from "../../constants/types";
import isEmpty from "../../validation/isEmpty";
import { SubHeader, PortletHead } from "../common";
import { Button, SelectListGroup, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";

const CreateInstitution = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    sender_id: "",
    api_key: "",
    payment_type: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { institution } = auth;
    if (!isEmpty(institution)) {
      history.push("/institution");
    }
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const institution = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      location: values.location,
      sender_id: values.sender_id,
      api_key: values.api_key,
      payment_type: values.payment_type,
    };
    dispatch(create(institution, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [flash, loading]);

  const paymentTypes = [
    { label: "Uniform Payment", value: 1 },
    { label: "Fixed-Rate Payment", value: 2 },
    { label: "Non-Uniform Payment", value: 3 },
  ];

  return (
    <React.Fragment>
      <SubHeader options={null} title="Create Institution" />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Set  institution" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              <div className="col-md-12">
                <form
                  className="m-form m-form--fit m-form--label-align-right"
                  onSubmit={onSubmit}
                >
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Name of Institution"
                        onChange={onChange}
                        value={values.name}
                        name="name"
                        type="text"
                        placeholder="Enter name of Institution"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Email"
                        type="text"
                        onChange={onChange}
                        value={values.email}
                        name="email"
                        placeholder="Enter email of institution"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Phone"
                        onChange={onChange}
                        value={values.phone}
                        name="phone"
                        type="text"
                        placeholder="Enter phone of institution"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Location"
                        onChange={onChange}
                        value={values.location}
                        name="location"
                        type="text"
                        placeholder="Enter location of institution"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="Sender ID"
                        onChange={onChange}
                        value={values.sender_id}
                        name="sender_id"
                        type="text"
                        placeholder="Enter Sender ID of institution"
                        min="11"
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextFieldGroup
                        label="API Key"
                        onChange={onChange}
                        value={values.api_key}
                        name="api_key"
                        type="text"
                        placeholder="Enter API key of institution"
                      />
                    </div>
                  </div>
                  <div className="form-group m-form__group row">
                    <div className="col-lg-6">
                      <SelectListGroup
                        label="Payment Type"
                        onChange={onChange}
                        value={`${values.payment_type}`}
                        name="payment_type"
                        placeholder="Enter Payment type of institution"
                        options={paymentTypes}
                      />
                    </div>
                  </div>
                  <div className="m-form__actions">
                    <Button
                      title={"Submit"}
                      className={"btn btn--black text-white"}
                      loading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateInstitution);
