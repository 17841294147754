import React from "react";
import { Link } from "react-router-dom";

const SignUpLink = () => {
  return (
    <div className="m-login__account">
      <span className="m-login__account-msg">Don't have an account yet ?</span>
      &nbsp;&nbsp;
      <Link
        to="sign-up"
        id="m_login_signup"
        className="m-link m-link--light m-login__account-link"
        style={{ color: "#ff7738" }}
      >
        Sign Up
      </Link>
    </div>
  );
};

export { SignUpLink };
