import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { generalPurposeAction } from "../../actions/common-action";
import { Title } from "../common";
import { TextFieldGroup, Button, RedirectLinks } from "../forms/guest";
import { reset } from "../../actions/auth-action";
import actionTypes from "../../constants/types";
import { displayToast } from "../common/Toaster";
import "./Auth.css";

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const flash = useSelector((state) => state.flash);
  const loading = useSelector((state) => state.loading);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    const { isAuthenticated } = auth;
    if (isAuthenticated) {
      history.push("/dashboard");
    }
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      email: email,
    };
    dispatch(reset(user, history));
  };

  useEffect(() => {
    const { success, message } = flash;
    const { isLoading } = loading;
    setIsLoading(isLoading);
    displayToast(message, success);
  }, [dispatch, flash, loading]);

  return (
    <div className="background-cover">
      <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2">
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
          <div className="m-login__container">
            <div className="login-section">
              <div className="m-login__signin">
                <Title title="Reset Password" />
                <form className="m-login__form m-form" onSubmit={onSubmit}>
                  <TextFieldGroup
                    placeholder={"Email"}
                    type={"text"}
                    value={email}
                    name={"email"}
                    onChange={onChange}
                  />

                  <div className="m-login__form-action">
                    <Button
                      title={"Receive New Password"}
                      className={"custom-button btn--black"}
                      loading={isLoading}
                    />
                    <RedirectLinks
                      title={"Remembered Password. Login"}
                      link={"/auth/login"}
                      className={"m-link m-link--dark m-login__account-link"}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
