import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SubHeader, EmptyBox, PortletHead, Spinner, Table } from "../common";
import {
  gets,
  update,
  grant_access,
  revoke_access,
} from "../../actions/users-action";
import { generalPurposeAction } from "../../actions/common-action";
import isEmpty from "../../validation/isEmpty";
import menuTypes from "../../constants/menu";
import actionTypes from "../../constants/types";
import { usersOptions } from "../../constants/mock";
import Modal from "../layout/Modal";
import modalTypes from "../../constants/modal-types";
import { Button, TextFieldGroup } from "../forms/auth";
import { displayToast } from "../common/Toaster";
import { UsersColumns } from "../common/TableColumns";

const Users = () => {
  const ur = useSelector((state) => state.users);
  const fr = useSelector((state) => state.flash);
  const lr = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [id, setId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    dispatch(
      generalPurposeAction(actionTypes.SET_ACTIVE_MENU, menuTypes.USERS)
    );
    dispatch(gets());
    return () => {
      dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      name: values.name,
      email: values.email,
      phone: values.phone,
    };
    dispatch(update(id, user));
  };

  useEffect(() => {
    const { users, loading } = ur;
    const { success, message } = fr;
    const { isLoading } = lr;
    if (!isEmpty(users)) {
      setUsers(users);
    }
    setIsLoading(loading);

    if (!isEmpty(id) && !isEmpty(users)) {
      const user = users.find((u) => u.id === id);
      setValues({
        name: user.name,
        email: !isEmpty(user.email) ? user.email : "",
        phone: !isEmpty(user.phone) ? user.phone : "",
      });
    }
    setButtonLoading(isLoading);
    displayToast(message, success);
  }, [ur, id, fr, lr]);

  const modalToShow = (id, type) => {
    setShowModal(true);
    setModalType(type);
    setId(id);
  };

  const closeModal = () => {
    dispatch(generalPurposeAction(actionTypes.CLEAR_FLASH_MESSAGE, null));
    setShowModal(false);
  };

  const onGrantAccessClick = useCallback(
    (id) => {
      dispatch(grant_access(id));
    },
    [dispatch]
  );

  const onRevokeAccessClick = useCallback(
    (id) => {
      dispatch(revoke_access(id));
    },
    [dispatch]
  );

  const tableColumns = UsersColumns(
    modalToShow,
    modalTypes.EDIT_USER,
    onGrantAccessClick,
    onRevokeAccessClick
  );

  let content;
  if (isLoading) {
    content = (
      <div className="col-md-12">
        <Spinner />
      </div>
    );
  } else {
    if (Object.keys(users).length === 0) {
      content = (
        <EmptyBox
          link={"/users/create"}
          btnMessage={"Add User"}
          icon={"flaticon-users icon-large"}
          btnIcon={"fa fa-plus"}
          info={"No Users Found"}
        />
      );
    } else {
      content = (
        <div className="col-md-12">
          <Table data={users} columns={tableColumns} />
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <SubHeader title="Users" options={usersOptions} />
      <div className="m-content">
        <div className="m-portlet ">
          <PortletHead title="Users Details" />
          <div className="m-portlet__body">
            <div className="row m-row--no-padding m-row--col-separator-xl">
              {content}
            </div>
          </div>
        </div>
      </div>
      {showModal && modalType === modalTypes.EDIT_USER ? (
        <Modal show={showModal} onClose={closeModal} header="Edit Member">
          <div className="col-md-12">
            <form
              className="m-form m-form--fit m-form--label-align-right"
              onSubmit={onSubmit}
            >
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Name of User"
                    onChange={onChange}
                    value={values.name}
                    type="text"
                    name="name"
                    placeholder="Enter name of User"
                  />
                </div>
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="email"
                    type="text"
                    onChange={onChange}
                    value={values.email}
                    name="email"
                    placeholder="Enter email of user"
                  />
                </div>
              </div>
              <div className="form-group m-form__group row">
                <div className="col-lg-6">
                  <TextFieldGroup
                    label="Phone"
                    type="text"
                    onChange={onChange}
                    value={values.phone}
                    name="phone"
                    placeholder="Enter phone of user"
                  />
                </div>
              </div>
              <div className="m-form__actions">
                <Button
                  title="Update"
                  className={"btn btn--black text-white"}
                  loading={buttonLoading}
                />
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default Users;
