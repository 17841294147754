import actionTypes from "../constants/types";

const initialState = {
  success: null,
  message: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_FLASH_MESSAGE:
      return {
        ...state,
        message: action.payload.description.message,
        success: action.payload.success,
      };
    case actionTypes.CLEAR_FLASH_MESSAGE:
      return initialState;
    default:
      return state;
  }
}
