import React from "react";
import logo from "./ebits-logo.png";

const Logo = () => {
  return (
    <div>
      <img src={logo} alt="Logo" width="120" />
    </div>
  );
};

export { Logo };
