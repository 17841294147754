import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SecureLS from "secure-ls";
import { Logo } from "../common";
import roles from "../../constants/roles";
import { logout } from "../../actions/auth-action";
import isEmpty from "../../validation/isEmpty";

let ls = new SecureLS({ encodingType: "aes", isCompression: true });

const Header = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { user } = auth;
  const logo = ls.get("logo");
  const inst_name = ls.get("inst_name");

  const onLogoutClick = () => {
    dispatch(logout());
  };

  let baseLogo;
  switch (user.role) {
    case roles.ADMIN:
      baseLogo = <Logo />;
      break;
    case roles.SUPER:
      baseLogo = <Logo />;
      break;
    default:
      if (
        !isEmpty(logo) &&
        (user.role === roles.USER || user.role === roles.ADMIN)
      ) {
        baseLogo = <img src={logo} alt="Logo" width="80" height="60" />;
      } else {
        baseLogo = <Logo />;
      }
      break;
  }

  return (
    <header
      id="m_header"
      className="m-grid__item m-header"
      m-minimize-offset="200"
      m-minimize-mobile-offset="200"
    >
      <div className="m-container m-container--fluid m-container--full-height">
        <div className="m-stack m-stack--ver m-stack--desktop">
          {/*BEGIN: Brand*/}
          <div className="m-stack__item m-brand  m-brand--skin-light">
            <div className="m-stack m-stack--ver m-stack--general m-stack--fluid">
              <div className="m-stack__item m-stack__item--middle m-brand__logo">
                <Link to="#" className="m-brand__logo-wrapper">
                  {baseLogo}
                </Link>
              </div>
              <div className="m-stack__item m-stack__item--middle m-brand__tools">
                <Link
                  to="#"
                  id="m_aside_left_offcanvas_toggle"
                  className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
                >
                  <span />
                </Link>
                <Link
                  id="m_aside_header_topbar_mobile_toggle"
                  to="#"
                  className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
                >
                  <i className="flaticon-more" />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="navbar-text text-center navbar-center"
            style={{ textAlign: "center" }}
          >
            {!isEmpty(inst_name)
              ? inst_name.toLocaleUpperCase()
              : "EBITS CO LTD"}
          </div>
          <div
            className="m-stack__item m-stack__item--fluid m-header-head"
            id="m_header_nav"
          >
            <div
              id="m_header_topbar"
              className="m-topbar  m-stack m-stack--ver m-stack--general"
            >
              <div className="m-stack__item m-topbar__nav-wrapper">
                <ul className="m-topbar__nav m-nav m-nav--inline">
                  <li
                    className="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                    m-dropdown-toggle="click"
                  >
                    <Link to="#" className="m-nav__link m-dropdown__toggle">
                      <span className="m-topbar__userpic">
                        <img
                          src={user.avatar}
                          className="m--img-rounded m--marginless m--img-centered"
                          alt=""
                        />
                      </span>
                      <span className="m-nav__link-icon m-topbar__usericon  m--hide">
                        <span className="m-nav__link-icon-wrapper">
                          <i className="flaticon-user-ok" />
                        </span>
                      </span>
                      <span className="m-topbar__username m--hide">
                        {user.name}
                      </span>
                    </Link>
                    <div className="m-dropdown__wrapper">
                      <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
                      <div className="m-dropdown__inner">
                        <div className="m-dropdown__header m--align-center">
                          <div className="m-card-user m-card-user--skin-light">
                            <div className="m-card-user__pic">
                              <img
                                src={user.avatar}
                                className="m--img-rounded m--marginless"
                                alt="profile"
                              />
                            </div>
                            <div className="m-card-user__details">
                              <span className="m-card-user__name m--font-weight-500">
                                {user.name}
                              </span>
                              <Link
                                to=""
                                className="m-card-user__email m--font-weight-300 m-link"
                              >
                                {user.email}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="m-dropdown__body">
                          <div className="m-dropdown__content">
                            <ul className="m-nav m-nav--skin-light">
                              <li className="m-nav__section m--hide">
                                <span className="m-nav__section-text">
                                  Section
                                </span>
                              </li>
                              <li className="m-nav__item">
                                <Link to="/profile" className="m-nav__link">
                                  <i className="m-nav__link-icon flaticon-profile-1" />
                                  <span className="m-nav__link-title">
                                    <span className="m-nav__link-wrap">
                                      <span className="m-nav__link-text">
                                        My Profile
                                      </span>
                                    </span>
                                  </span>
                                </Link>
                              </li>
                              <li className="m-nav__item">
                                <Link
                                  to="/change-password"
                                  className="m-nav__link"
                                >
                                  <i className="m-nav__link-icon flaticon-lock" />
                                  <span className="m-nav__link-title">
                                    <span className="m-nav__link-wrap">
                                      <span className="m-nav__link-text">
                                        Change Password
                                      </span>
                                    </span>
                                  </span>
                                </Link>
                              </li>
                              <li className="m-nav__separator m-nav__separator--fit" />
                              <li className="m-nav__separator m-nav__separator--fit" />
                              <li className="m-nav__item">
                                <button
                                  className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                  onClick={() => onLogoutClick()}
                                >
                                  Logout
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
